import Joi from 'joi';

const baseSchema = Joi.object({
  id: Joi.string().allow(''),
  userId: Joi.string().allow(''),
  displayId: Joi.string().allow(''),
  activityDate: Joi.date().allow(''),
  activityType: Joi.string().required().messages({
    'string.empty': 'Please select activity type',
  }),
  distributerName: Joi.string().allow('').max(50).messages({
    'string.max': 'Distributer name must be less than or equal to 50 characters long',
  }),
  salesPersonName: Joi.string().allow('').max(50).messages({
    'string.empty': 'Sales person name should not be empty',
    'string.max': 'Sales person name must be less than or equal to 50 characters long',
  }),
  salesPersonMobile: Joi.string()
    .allow('')
    .min(10)
    .max(10)
    .pattern(/^[1-9](\d){9}$/)
    .messages({
      'string.empty': 'Sales person mobile should not be empty',
      'string.min': 'Sales person mobile length must be at least 10 characters long',
      'string.max': 'Sales person mobile length must be less than or equal to 10 characters long',
      'string.pattern.base': 'Invalid Mobile',
    }),
  outletCode: Joi.string().required().messages({
    'string.empty': 'Outlet code should not be empty',
  }),
  outletName: Joi.string().required().max(50).messages({
    'string.empty': 'Outlet name should not be empty',
    'string.max': 'Outlet name must be less than or equal to 50 characters long',
  }),
  outletMobile: Joi.string()
    .required()
    .min(10)
    .max(10)
    .pattern(/^[1-9](\d){9}$/)
    .messages({
      'string.empty': 'Outlet mobile should not be empty',
      'string.min': 'Outlet mobile length must be at least 10 characters long',
      'string.max': 'Outlet mobile length must be less than or equal to 10 characters long',
      'string.pattern.base': 'Invalid Mobile',
    }),
  brandingTypeId: Joi.string().required().messages({
    'string.empty': 'Please select a branding type',
  }),
  visibilityElementId: Joi.string().required().messages({
    'string.empty': 'Please select a visibility element',
  }),
  remark: Joi.string().allow('').messages({
    'string.empty': 'Remark should not be empty',
  }),
  lat: Joi.number().allow(''),
  lng: Joi.number().allow(''),
  state: Joi.string().allow(''),
  town: Joi.string().allow(''),
  district: Joi.string().allow(''),
  attachments: Joi.array()
    .items(
      Joi.object().required().messages({
        'object.empty': 'Attachments should not be empty',
      }),
    )
    .messages({
      'array.includesRequiredUnknowns': 'Attachments should not be empty',
    }),

  client: Joi.object().required().messages({
    'any.required': 'Please select a client',
  }),
});

const receeActivitySchema = Joi.object({
  boardWidth: Joi.number().required().precision(2).messages({
    'number.base': 'Width should be a number',
    'number.precision': 'Maxiumum 2 decimal places allowed',
  }),
  boardHeight: Joi.number().precision(2).required().messages({
    'number.base': 'Height should be a number',
    'number.precision': 'Maxiumum 2 decimal places allowed',
  }),
  quantity: Joi.number().required().messages({
    'number.base': 'Quantity should be a number',
    'number.precision': 'Maxiumum 2 decimal places allowed',
  }),
  acknowledgements: Joi.array()
    .items(
      Joi.object().required().messages({
        'object.empty': 'Acknowledgements should not be empty',
      }),
    )
    .messages({
      'array.includesRequiredUnknowns': 'Acknowledgements should not be empty',
    }),
}).concat(baseSchema);

const installationActivitySchema = Joi.object({
  acknowledgements: Joi.array()
    .items(
      Joi.object().required().messages({
        'object.empty': 'Acknowledgements should not be empty',
      }),
    )
    .messages({
      'array.includesRequiredUnknowns': 'Acknowledgements should not be empty',
    }),
}).concat(baseSchema);

const rectificationActivitySchema = baseSchema;

const brandingActivityValidationSchema = {
  recee: receeActivitySchema,
  installation: installationActivitySchema,
  rectification: rectificationActivitySchema,
};

export default brandingActivityValidationSchema;
