import Joi from 'joi';

export const mobileSchema = Joi.string()
  .required()
  .min(10)
  .max(10)
  .pattern(/^[1-9](\d){9}$/)
  .messages({
    'string.empty': 'Mobile should not be empty',
    'string.min': 'Mobile number length must be at least 10 characters long',
    'string.max': 'Mobile number length must be less than or equal to 10 characters long',
    'string.pattern.base': 'Invalid Mobile number',
  });
