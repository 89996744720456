import Joi from 'joi';

export const visibilityElementFormSchema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name should not be empty',
  }),
  brandingTypeId: Joi.string().required().messages({
    'string.empty': 'Please select branding type',
  }),
});
