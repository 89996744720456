import React from 'react';
import { useHistory } from 'react-router-dom';

interface WithConditionRedirectProps<P> {
  WrapperComponent: React.ComponentType<P>;
  redirectCondition: () => boolean;
  isLoading: () => boolean;
  location: string;
}

interface WithConditionRedirectWrapperProps {}
export default function withConditionRedirect<P>({
  WrapperComponent,
  redirectCondition,
  isLoading,
  location,
}: WithConditionRedirectProps<P>) {
  const WithConditionRedirectWrapper: React.FunctionComponent<
    P & WithConditionRedirectWrapperProps
  > = ({ ...props }) => {
    const history = useHistory();
    const loading = isLoading();
    const condition = redirectCondition();

    if (loading) {
      return <div>Loading</div>;
    }
    if (condition) {
      history.push(location);
      return <></>;
    }
    return <WrapperComponent {...props} />;
  };
  return WithConditionRedirectWrapper;
}
