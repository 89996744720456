import { ClientUser } from '../@types';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';

interface GetAllClientUserResult {
  clientUsers?: ClientUser[];
  count?: number;
  error?: string;
}

interface GetAllClientUserResponse {
  clientUsers: ClientUser[];
  count: number;
}

const getAllClientUserByClientId = async (clientId: string) => {
  const result: GetAllClientUserResult = {};
  try {
    const {
      data: { clientUsers, count },
    } = await API.get<GetAllClientUserResponse>(`/clients/${clientId}/users`, {});
    result.clientUsers = clientUsers;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateClientUserResponse {
  clientUser: ClientUser;
  message: string;
}
interface CreateClientUserResult {
  clientUser?: ClientUser;
  message?: string;
  error?: string;
}

const createClientUser = async (clientUserData: ClientUser) => {
  const result: CreateClientUserResult = {};
  try {
    const {
      data: { clientUser, message },
    } = await API.post<CreateClientUserResponse>(`/clients/${clientUserData.clientId}/users`, clientUserData);
    result.clientUser = clientUser;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteClientUserResult {
  message?: string;
  error?: string;
}
interface DeleteClientUserResponse {
  message: string;
}

const deleteClientUser = async (id: string, clientId: string): Promise<DeleteClientUserResult> => {
  const result: DeleteClientUserResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteClientUserResponse>(`/clients/${clientId}/users/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const ClientUserService = {
  getAllClientUserByClientId,
  createClientUser,
  deleteClientUser,
};

export default ClientUserService;
