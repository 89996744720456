import {
  Paper,
  Tab,
  Table,
  Tabs,
  Typography,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  IconButton,
  Divider,
  Box,
  TextField,
  InputAdornment,
  Button,
  TablePagination,
  Chip,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { User } from '../@types/user';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { BreadcrumbsLink } from '../components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from '../components/MainLayout';
import withAuth from '../hocs/withAuth';
import UserService from '../services/user.service';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Link, useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { ROLE } from '../enums/role';
import Empty from '../components/common/Empty';
import LoadingIndicator from '../components/common/LoadingIndicator';
import withRoles from '../hocs/withRoles';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { AuthState } from '../store/auth/types';
import { hasRole } from '../util/role';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Management',
    href: '/management/users',
  },
  {
    label: 'Users',
  },
];

const roleTabs = [
  {
    label: 'ALL',
    key: ROLE.ALL,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    label: 'Super Admin',
    key: ROLE.SUPER_ADMIN,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    label: 'Admin',
    key: ROLE.ADMIN,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    label: 'Manager',
    key: ROLE.MANAGER,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    label: 'Client',
    key: ROLE.CLIENT,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
  },
  {
    label: 'Promoter',
    key: ROLE.PROMOTER,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
  },
];

const Users = () => {
  const history = useHistory();
  const { user } = useSelector<RootState, AuthState>((state) => state.authState);
  const [users, setUsers] = useState<User[]>([]);
  const [filterOption, setFilterOption] = useState({
    query: '',
    page: 1,
    size: 10,
    role: ROLE.ALL,
  });
  const rowsPerPageOptions = [10, 20, 30, 40, 50];
  const [userCount, setUserCount] = useState(0);
  const [loading, setLoading] = useState(false);
  // filter role tabs based on user roles
  const filterRoleTabs = roleTabs.filter(
    // (roleTab) => user?.roles?.some((role) => (roleTab.roles as string[]).indexOf(role.key) > -1), //check user has roles which are persent in roles of roleTab object
    (roleTab) => user && hasRole(user.roles, roleTab.roles),
  );

  const getAllUser = async () => {
    setLoading(true);
    const { users = [], count = 0, error } = await UserService.getAllUser(filterOption);
    setLoading(false);
    if (error) {
    } else {
      setUsers(users);
      setUserCount(count);
    }
  };

  useEffect(() => {
    getAllUser();
    // eslint-disable-next-line
  }, [filterOption]);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: ROLE) => {
    setFilterOption({ ...filterOption, query: '', role: newValue });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setFilterOption({ ...filterOption, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOption({ ...filterOption, page: 1, size: parseInt(event.target.value, 10) });
  };

  const debounceSearch = debounce((value: string) => {
    setFilterOption({ ...filterOption, page: 1, query: value });
  }, 300);

  const handleUserSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value);
  };

  return (
    <MainLayout>
      <Box className="flex justify-between">
        <Box>
          <Breadcrumbs links={links} />
          <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
            All Users
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            component={Link}
            to="/management/users/new"
          >
            <PersonAddIcon className="mr-2" />
            New user
          </Button>
        </Box>
      </Box>

      <Paper className="mt-5" elevation={1}>
        <Tabs
          value={filterOption.role}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {filterRoleTabs.map((roleTab) => (
            <Tab label={roleTab.label} value={roleTab.key} key={roleTab.key} />
          ))}
        </Tabs>
        <Divider />
        <Box className="mt-2 p-4">
          <TextField
            variant="outlined"
            placeholder="Search users"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            className="w-1/3"
            onChange={handleUserSearch}
          />
        </Box>
        <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
          <Empty isEmpty={users.length === 0} imgClassName="w-4/12">
            <TableContainer className="mt-2">
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Roles</TableCell>
                    <TableCell>Modules</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow
                      key={user.id}
                      className="cursor-pointer"
                      onClick={() => history.push(`/management/users/${user.id}`)}
                      hover
                    >
                      <TableCell>{user.displayId}</TableCell>
                      <TableCell component="th" scope="row">
                        <div className="flex items-center">
                          {/* <Avatar src={user.imageUrl} alt={user.name} className="mr-4" /> */}
                          <Typography
                            component={Link}
                            to={`/management/users/${user.id}`}
                            color="textPrimary"
                          >
                            {user.name}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>{user.mobile}</TableCell>
                      <TableCell className="uppercase">{user.gender}</TableCell>
                      <TableCell>
                        {user?.roles.map((role) => (
                          <Chip label={role.name} key={role.key} size="small" className="mr-2" />
                        ))}
                      </TableCell>
                      <TableCell>
                        {user.modules.map((module) => (
                          <Chip
                            label={module.name}
                            key={module.key}
                            size="small"
                            className="mr-2"
                          />
                        ))}
                      </TableCell>
                      <TableCell className="font-bold" align="center">
                        <IconButton
                          component={Link}
                          to={`/management/users/${user.id}/edit`}
                          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                            event.stopPropagation()
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          component={Link}
                          to={`/management/users/${user.id}`}
                          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                            event.stopPropagation()
                          }
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={userCount}
              rowsPerPage={filterOption.size}
              page={filterOption.page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Empty>
        </LoadingIndicator>
      </Paper>
    </MainLayout>
  );
};

export default withAuth(withRoles(Users, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]), '/login');
