import { BrandingType } from '../@types/branding';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';
import qs from 'qs';
import { FilterType } from '../@types';

interface GeAllBrandingTypeOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: FilterType;
}

interface GetAllBrandingTypeResult {
  brandingTypes?: BrandingType[];
  count?: number;
  error?: string;
}

interface GetAllBrandingTypeResponse {
  brandingTypes: BrandingType[];
  count: number;
}

const getAllBrandingType = async ({ page, size, query, filterType }: GeAllBrandingTypeOptions) => {
  const result: GetAllBrandingTypeResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, filterType });
    const {
      data: { brandingTypes, count },
    } = await API.get<GetAllBrandingTypeResponse>(`/branding-types?${queryString}`, {});
    result.brandingTypes = brandingTypes;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetBrandingTypeByIdResult {
  brandingType?: BrandingType;
  error?: string;
}

interface GetBrandingTypeByIdResponse {
  brandingType: BrandingType;
}

const getBrandingTypeById = async (brandingTypeId: string) => {
  const result: GetBrandingTypeByIdResult = {};
  try {
    const {
      data: { brandingType },
    } = await API.get<GetBrandingTypeByIdResponse>(`branding-types/${brandingTypeId}`);
    result.brandingType = brandingType;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateBrandingTypeResponse {
  brandingType: BrandingType;
  message: string;
}
interface CreateBrandingTypeResult {
  brandingType?: BrandingType;
  message?: string;
  error?: string;
}

const createBrandingType = async (brandingTypeData: BrandingType) => {
  const result: CreateBrandingTypeResult = {};
  try {
    const {
      data: { brandingType, message },
    } = await API.post<CreateBrandingTypeResponse>('branding-types', brandingTypeData);
    result.brandingType = brandingType;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateBrandingTypeResponse {
  brandingType: BrandingType;
  message: string;
}

interface UpdateBrandingTypeResult {
  brandingType?: BrandingType;
  message?: string;
  error?: string;
}

const updateBrandingType = async (brandingTypeData: BrandingType) => {
  const result: UpdateBrandingTypeResult = {};
  try {
    // const brandingTypeFormData: BrandingTypeFormData = transformToBrandingTypeFormData(brandingTypeData);
    const {
      data: { brandingType, message },
    } = await API.put<UpdateBrandingTypeResponse>(
      `branding-types/${brandingTypeData.id}`,
      brandingTypeData,
    );
    result.brandingType = brandingType;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteBrandingTypeResult {
  message?: string;
  error?: string;
}
interface DeleteBrandingTypeResponse {
  message: string;
}

const deleteBrandingType = async (id: string): Promise<DeleteBrandingTypeResult> => {
  const result: DeleteBrandingTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteBrandingTypeResponse>(`branding-types/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateBrandingTypeResult {
  message?: string;
  error?: string;
}
interface DeactivateBrandingTypeResponse {
  message: string;
}

const deactivateBrandingType = async (id: string): Promise<DeactivateBrandingTypeResult> => {
  const result: DeleteBrandingTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateBrandingTypeResponse>(`branding-types/${id}/deactivate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateBrandingTypeResult {
  message?: string;
  error?: string;
}
interface ActivateBrandingTypeResponse {
  message: string;
}
const activateBrandingType = async (id: string): Promise<ActivateBrandingTypeResult> => {
  const result: DeleteBrandingTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateBrandingTypeResponse>(`branding-types/${id}/activate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const BrandingTypeService = {
  getAllBrandingType,
  getBrandingTypeById,
  createBrandingType,
  updateBrandingType,
  deleteBrandingType,
  deactivateBrandingType,
  activateBrandingType,
};

export default BrandingTypeService;
