import { Typography } from '@material-ui/core';
import React from 'react';

interface FileSizeProps {
  size: number; // file size in bytes
}

const FileSize: React.FunctionComponent<FileSizeProps> = ({ size }) => {
  const sizeInMB = (size / (1024 * 1024)).toFixed(2);
  return <Typography variant="caption">{sizeInMB}MB</Typography>;
};

export default FileSize;