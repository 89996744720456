import { User } from '../../@types/user';

export interface AuthState {
  user: User | null;
  authenticated: boolean;
  sessionLoading: boolean;
}

export const SET_USER = '@auth/set-user';
export const LOGOUT = '@auth/logout';
export const START_SESSION_LOADING = '@auth/start-session-loading';
export const SESSION_LOAD_SUCCESS = '@auth/session-load-success';
export const SESSION_LOAD_ERROR = '@auth/session-load-error';

export interface SetUserAction {
  type: typeof SET_USER;
  payload: {
    user: User;
  };
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface StartSessionLoadingAction {
  type: typeof START_SESSION_LOADING;
}

export interface SessionLoadSuccessAction {
  type: typeof SESSION_LOAD_SUCCESS;
  payload: {
    user: User;
  };
}

export interface SessionLoadErrorAction {
  type: typeof SESSION_LOAD_ERROR;
}

export type AuthActionTypes =
  | SetUserAction
  | LogoutAction
  | StartSessionLoadingAction
  | SessionLoadSuccessAction
  | SessionLoadErrorAction;
