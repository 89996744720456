import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useContext } from 'react';
import { Control, Controller } from 'react-hook-form';
import DateUtilContext from '../../../context/DateUtilContext';

interface DatePickerControllerPros {
  control: Control;
  error?: boolean;
  helperText?: string;
  label: string;
  className?: string;
  name: string;
  id: string;
  shouldDisableDate?: (day: MaterialUiPickersDate) => boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

const DatePickerController: React.FunctionComponent<DatePickerControllerPros> = ({
  control,
  error,
  helperText,
  label,
  className,
  id,
  name,
  shouldDisableDate,
  disabled,
  size,
}) => {
  const { displayFormat } = useContext(DateUtilContext);
  return (
    <Controller
      as={
        <KeyboardDatePicker
          margin="normal"
          label={label}
          format={displayFormat}
          onChange={() => {}}
          value={() => {}}
          inputVariant="outlined"
          disableFuture
          className={className}
          error={error}
          helperText={helperText}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
          clearable
          size={size}
        />
      }
      control={control}
      id={id}
      name={name}
    />
  );
};

export default DatePickerController;
