import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { VisibilityElement } from '../../@types/branding';
import VisibilityElementService from '../../services/visibility-element.service';
import VisibilityElementFormDialog from './VisibilityElementFormDialog';

interface EditVisibilityElementDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  visibilityElementId: string;
}

const EditVisibilityElementDialog: React.FC<EditVisibilityElementDialogProps> = ({
  open,
  onClose,
  onSuccess,
  visibilityElementId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [visibilityElement, setVisibilityElement] = useState<VisibilityElement | null>(null);

  const handleSave = useCallback(async (data: VisibilityElement) => {
    const { message, error } = await VisibilityElementService.updateVisibilityElement({
      ...data,
      id: visibilityElementId,
    });
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getVisibilityElementById = async () => {
      const { visibilityElement } = await VisibilityElementService.getVisibilityElementById(
        visibilityElementId,
      );
      if (visibilityElement) {
        setVisibilityElement(visibilityElement);
      }
    };
    getVisibilityElementById();
    // eslint-disable-next-line
  }, [visibilityElementId]);

  return (
    <>
      {visibilityElement && (
        <VisibilityElementFormDialog
          mode="edit"
          visibilityElement={visibilityElement}
          open={open}
          onClose={onClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditVisibilityElementDialog;
