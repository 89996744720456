import Joi from 'joi';
import { mobileSchema } from './common';

export const clientFormSchema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name should not be empty',
  }),
});

export const clientUserFormSchema = Joi.object({
  mobile: mobileSchema,
});
