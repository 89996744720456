import React, { FunctionComponent } from 'react';
import Layout from '../components/Layout';
import PageNotFoundImage from '../assets/images/page_not_found.svg';
import { Typography } from '@material-ui/core';

const PageNotFound: FunctionComponent = () => {
  return (
    <Layout className="flex-col justify-center items-center">
      <img src={PageNotFoundImage} alt="Page not found" className="w-4/12" />
      <Typography variant="h4" className="text-center mt-8">
        The page you requested was not found .
      </Typography>
    </Layout>
  );
};

export default PageNotFound;
