import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../../@types/user';
import UserService from '../../../services/user.service';
import ChangePasswordDialog from './ChangePasswordDialog';
import DeleteUserDialog from './DeleteUserDialog';
import UserAddressCard from './UserAddressCard';
import UserIdentityCard from './UserIdentityCard';
import UserInfoCard from './UserInfoCard';
import UserOtherActionCard from './UserOtherActionCard';

interface UserDetailProps {
  user: User;
  onUpdate?: () => void;
}

const UserDetail: React.FC<UserDetailProps> = ({ user, onUpdate }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);

  const deleteUser = async () => {
    const { message, error } = await UserService.deleteUser(user.id);
    if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      history.push('/management/users');
    } else if (error) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };
  const deactivate = async () => {
    const { message, error } = await UserService.deactivateUser(user.id);
    if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onUpdate?.();
    } else if (error) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };
  const activate = async () => {
    const { message, error } = await UserService.activateUser(user.id);
    if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onUpdate?.();
    } else if (error) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const changePassword = async (password: string) => {
    const { message, error } = await UserService.changePassword(user.id, password);
    if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      closePasswordDialog();
    } else if (error) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const showPasswordDialog = () => {
    setOpenPasswordDialog(true);
  };
  const closePasswordDialog = () => {
    setOpenPasswordDialog(false);
  };

  const showDeleteUserDialog = () => {
    setOpenDeleteUserDialog(true);
  };
  const closeDeleteUserDialog = () => {
    setOpenDeleteUserDialog(false);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <UserInfoCard user={user} />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <UserAddressCard user={user} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <UserIdentityCard user={user} />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <UserOtherActionCard
          user={user}
          onChangePassword={showPasswordDialog}
          onDeactive={deactivate}
          onActivate={activate}
          onDelete={showDeleteUserDialog}
        />
      </Grid>
      {openPasswordDialog && (
        <ChangePasswordDialog
          open={openPasswordDialog}
          onClose={closePasswordDialog}
          onChange={changePassword}
        />
      )}
      {openDeleteUserDialog && (
        <DeleteUserDialog
          open={openDeleteUserDialog}
          onClose={closeDeleteUserDialog}
          onDelete={deleteUser}
        />
      )}
    </Grid>
  );
};

export default UserDetail;
