import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BrandingType, VisibilityElement } from '../../@types/branding';
import DialogTitle from '../../components/common/Dialog/DialogTitle';
import BrandingTypeService from '../../services/branding-type.service';
import { visibilityElementFormSchema } from '../../validation/visibility-element';

interface VisibilityElementFormProps {
  mode: 'create' | 'edit';
  open: boolean;
  visibilityElement: VisibilityElement;
  onSave?: (visibilityElement: VisibilityElement) => void;
  onClose?: () => void;
}

const VisibilityElementFormDialog: React.FC<VisibilityElementFormProps> = ({
  mode,
  visibilityElement,
  onSave,
  open,
  onClose,
}) => {
  const { register, errors, handleSubmit, control } = useForm<VisibilityElement>({
    defaultValues: visibilityElement,
    mode: 'onSubmit',
    resolver: joiResolver(visibilityElementFormSchema),
  });
  const [brandingTypes, setbrandingTypes] = useState<BrandingType[]>([]);

  const onSubmit = (data: VisibilityElement) => {
    onSave?.(data);
  };

  useEffect(() => {
    const getAllBrandingType = async () => {
      const { brandingTypes = [] } = await BrandingTypeService.getAllBrandingType({
        filterType: 'all',
      });
      setbrandingTypes(brandingTypes);
    };
    getAllBrandingType();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          title={mode === 'create' ? 'Create visibility element' : 'Update visibility element'}
          onClose={onClose}
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Name"
                className="w-full"
                id="name"
                name="name"
                error={!!errors.name}
                helperText={errors.name?.message}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className="w-full" error={!!errors.brandingTypeId}>
                <InputLabel id="branding-type-label">Branding Type</InputLabel>
                <Controller
                  name="brandingTypeId"
                  control={control}
                  as={
                    <Select labelId="branding-type-label" label="Branding Type">
                      {brandingTypes.map((brandingType) => (
                        <MenuItem value={brandingType.id} key={brandingType.id}>
                          {brandingType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                <FormHelperText>{errors?.brandingTypeId?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {mode === 'create' ? 'Create' : 'Save '}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default VisibilityElementFormDialog;
