import { ActivityType } from '../enums/branding';
import brandingActivityValidationSchema from '../validation/branding-activity';

export const getBrandingActivitySchema = (activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.RECEE:
      return brandingActivityValidationSchema.recee;
    case ActivityType.INSTALLATION:
      return brandingActivityValidationSchema.installation;
    case ActivityType.RECTIFICATION:
      return brandingActivityValidationSchema.rectification;
  }
};
