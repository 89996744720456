import React from 'react';
import { Controller, Control } from 'react-hook-form';
import MuiAutocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete';
import { Chip, TextField } from '@material-ui/core';

interface AutocompleteControllerProps<T> {
  options: T[];
  control: Control;
  error?: boolean;
  helperText?: string;
  getOptionLabel?: (option: T) => string;
  defaultValue?: T | T[];
  label: string;
  className?: string;
  placeholder?: string;
  name: string;
  id: string;
  getOptionSelected?: (option: T, value: T) => boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
}

function AutocompleteController<T>({
  options,
  control,
  error,
  helperText,
  getOptionLabel,
  defaultValue,
  label,
  className,
  placeholder,
  name,
  id,
  getOptionSelected,
  size,
  multiple,
  onInputChange,
}: AutocompleteControllerProps<T>): JSX.Element {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <MuiAutocomplete
          multiple={multiple}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          onInputChange={onInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              className={className}
              placeholder={placeholder}
              error={error}
              helperText={helperText}
              size={size}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={getOptionLabel?.(option)} {...getTagProps({ index })} size={size} />
            ))
          }
          onChange={(event, data) => {
            onChange(data);
          }}
          {...props}
        />
      )}
      control={control}
      name={name}
      id={id}
      defaultValue={defaultValue}
    />
  );
}

export default AutocompleteController;
