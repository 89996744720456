import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ClientUserForm } from '../../@types';
import DialogTitle from '../../components/common/Dialog/DialogTitle';
import { clientUserFormSchema } from '../../validation/client';

interface ClientUserFormProps {
  mode: 'create' | 'edit';
  open: boolean;
  clientUser: ClientUserForm;
  onSave?: (clientUser: ClientUserForm) => void;
  onClose?: () => void;
}

const ClientUserFormDialog: React.FC<ClientUserFormProps> = ({
  mode,
  clientUser,
  onSave,
  open,
  onClose,
}) => {
  const { register, errors, handleSubmit } = useForm<ClientUserForm>({
    defaultValues: clientUser,
    mode: 'onSubmit',
    resolver: joiResolver(clientUserFormSchema),
  });

  const onSubmit = (data: ClientUserForm) => {
    onSave?.(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle title={mode === 'create' ? 'Add Client' : 'Edit Client'} onClose={onClose} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Enter user mobile no."
                id="mobile"
                name="mobile"
                error={!!errors.mobile}
                helperText={errors.mobile?.message}
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {mode === 'create' ? 'Add' : 'Save '}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ClientUserFormDialog;
