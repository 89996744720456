import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Client, ClientFilterOption } from '../@types/client';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { BreadcrumbsLink } from '../components/common/Breadcrumbs/Breadcrumbs';
import Empty from '../components/common/Empty';
import LoadingIndicator from '../components/common/LoadingIndicator';
import MainLayout from '../components/MainLayout';
import { ROLE } from '../enums/role';
import withAuth from '../hocs/withAuth';
import withRoles from '../hocs/withRoles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import ClientService from '../services/client.service';
import CreateClientDialog from '../containers/Client/CreateClientDialog';
import EditClientDialog from '../containers/Client/EditingClientDialog';
import DeleteClientDialog from '../containers/Client/DeleteClientDialog';
import debounce from 'lodash.debounce';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Management',
    href: '/management/users',
  },
  {
    label: 'Brands',
  },
];

const rowsPerPageOptions = [10, 20, 30, 40, 50];

const Clients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [clientId, setClientId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterOption, setFilterOption] = useState<ClientFilterOption>({
    query: '',
    page: 1,
    size: 20,
    filterType: 'filter',
  });

  const [clientCount, setClientCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setFilterOption({ ...filterOption, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOption({ ...filterOption, page: 1, size: parseInt(event.target.value, 10) });
  };

  const getAllClient = async () => {
    setLoading(true);
    const { clients = [], count = 0, error } = await ClientService.getAllClient(filterOption);
    setClientCount(count);
    setLoading(false);
    if (error) {
    } else {
      setClients(clients);
    }
  };

  const debounceSearch = debounce((value: string) => {
    setFilterOption({ ...filterOption, page: 1, query: value });
  }, 300);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value);
  };

  const handleCreateClientSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllClient();
    // eslint-disable-next-line
  }, []);

  const handleEditClick = useCallback(
    (clientId: string) => () => {
      setClientId(clientId);
      setOpenEditDialog(true);
    },
    // eslint-disable-next-line
    [],
  );

  const handleEditDialogClose = useCallback(() => {
    setOpenEditDialog(false);
    setClientId(null);
    // eslint-disable-next-line
  }, []);

  const handleEditClientSuccess = useCallback(() => {
    setOpenEditDialog(false);
    setClientId(null);
    getAllClient();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (clientId: string) => () => {
      setClientId(clientId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    [],
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setClientId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteClientSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setClientId(null);
    getAllClient();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllClient();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
                All Brands
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setOpenCreateDialog(true)}
              >
                New Brand
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper className="mt-5" elevation={1}>
        <Box padding={3}>
          <Grid spacing={3}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                placeholder="Search"
                id="search"
                name="search"
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
          <Empty isEmpty={clients.length === 0} imgClassName="w-4/12">
            <TableContainer className="mt-2">
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client, index) => (
                    <TableRow key={client.id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {client.name}
                      </TableCell>
                      <TableCell className="font-bold" align="center">
                        <IconButton onClick={handleEditClick(client.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDeleteClick(client.id)}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton component={Link} to={`/management/brands/${client.id}/clients`}>
                          <PeopleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={clientCount}
              rowsPerPage={filterOption.size}
              page={filterOption.page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Empty>
        </LoadingIndicator>
      </Paper>
      <CreateClientDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={handleCreateClientSuccess}
      />
      {clientId && (
        <EditClientDialog
          open={openEditDialog}
          onClose={handleEditDialogClose}
          onSuccess={handleEditClientSuccess}
          clientId={clientId}
        />
      )}
      {clientId && (
        <DeleteClientDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteClientSuccess}
          clientId={clientId}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(withRoles(Clients, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]), '/login');
