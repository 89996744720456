import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import { ROLE } from '../enums/role';
import withAuth from '../hocs/withAuth';
import { RootState } from '../store';
import { AuthState } from '../store/auth/types';
import { hasRole } from '../util/role';

const Dashboard = () => {
  const history = useHistory();
  const { user } = useSelector<RootState, AuthState>((state) => state.authState);
  if (user) {
    if (hasRole(user.roles, [ROLE.SUPER_ADMIN, ROLE.ADMIN])) {
      history.replace('/management/users');
      return <></>;
    } else if (hasRole(user.roles, [ROLE.MANAGER, ROLE.CLIENT])) {
      history.replace('/branding/activities');
      return <></>;
    }
  }
  return <MainLayout></MainLayout>;
};

export default withAuth(Dashboard, '/login');
