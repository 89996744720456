import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsLink } from '../components/common/Breadcrumbs/Breadcrumbs';
import Empty from '../components/common/Empty';
import LoadingIndicator from '../components/common/LoadingIndicator';
import MainLayout from '../components/MainLayout';
import { ROLE } from '../enums/role';
import withAuth from '../hocs/withAuth';
import withRoles from '../hocs/withRoles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityElementService from '../services/visibility-element.service';
import { VisibilityElement } from '../@types/branding';
import CreateVisibilityElementDialog from '../containers/VisibilityElement/CreateVisibilityElementDialog';
import EditVisibilityElementDialog from '../containers/VisibilityElement/EditingVisibilityElementTypeDialog';
import DeleteVisibilityElementDialog from '../containers/VisibilityElement/DeleteVisibilityElementDialog';
import { VisibilityElementFilterOption } from '../@types';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Branding',
    href: '/branding/visibility-elements',
  },
  {
    label: 'Visibility Elements',
  },
];

const VisibilityElements = () => {
  const [visibilityElements, setVisibilityElements] = useState<VisibilityElement[]>([]);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [visibilityElementId, setVisibilityElementId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterOption, setFilterOption] = useState<VisibilityElementFilterOption>({
    query: '',
    page: 1,
    size: 20,
    filterType: 'filter',
  });
  const rowsPerPageOptions = [10, 20, 30, 40, 50];
  const [visibilityElementCount, setVisibilityElementCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setFilterOption({ ...filterOption, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOption({ ...filterOption, page: 1, size: parseInt(event.target.value, 10) });
  };

  const getAllVisibilityElement = async () => {
    setLoading(true);
    const {
      visibilityElements = [],
      count = 0,
      error,
    } = await VisibilityElementService.getAllVisibilityElement(filterOption);
    setVisibilityElementCount(count);
    setLoading(false);
    if (error) {
    } else {
      setVisibilityElements(visibilityElements);
    }
  };

  const handleCreateBrandinTypeSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllVisibilityElement();
    // eslint-disable-next-line
  }, []);

  const handleEditClick = useCallback(
    (visibilityElementId: string) => () => {
      setVisibilityElementId(visibilityElementId);
      setOpenEditDialog(true);
    },
    // eslint-disable-next-line
    [],
  );

  const handleEditDialogClose = useCallback(() => {
    setOpenEditDialog(false);
    setVisibilityElementId(null);
    // eslint-disable-next-line
  }, []);

  const handleEditBrandinTypeSuccess = useCallback(() => {
    setOpenEditDialog(false);
    setVisibilityElementId(null);
    getAllVisibilityElement();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (visibilityElementId: string) => () => {
      setVisibilityElementId(visibilityElementId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    [],
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setVisibilityElementId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteBrandinTypeSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setVisibilityElementId(null);
    getAllVisibilityElement();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllVisibilityElement();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
          <Grid container justify="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
                All Visibility Element
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setOpenCreateDialog(true)}
              >
                New visibility element
              </Button>
            </Grid>
          </Grid>
          <Paper className="mt-5" elevation={1}>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={visibilityElements.length === 0} imgClassName="w-4/12">
                <TableContainer className="mt-2">
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Branding Type</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibilityElements.map((visibilityElement, index) => (
                        <TableRow key={visibilityElement.id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {visibilityElement.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {visibilityElement.brandingType?.name}
                          </TableCell>
                          <TableCell className="font-bold" align="center">
                            <IconButton onClick={handleEditClick(visibilityElement.id)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={handleDeleteClick(visibilityElement.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={visibilityElementCount}
                  rowsPerPage={filterOption.size}
                  page={filterOption.page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Empty>
            </LoadingIndicator>
          </Paper>
        </Grid>
      </Grid>
      <CreateVisibilityElementDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={handleCreateBrandinTypeSuccess}
      />
      {visibilityElementId && (
        <EditVisibilityElementDialog
          open={openEditDialog}
          onClose={handleEditDialogClose}
          onSuccess={handleEditBrandinTypeSuccess}
          visibilityElementId={visibilityElementId}
        />
      )}
      {visibilityElementId && (
        <DeleteVisibilityElementDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteBrandinTypeSuccess}
          visibilityElementId={visibilityElementId}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(VisibilityElements, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  '/login',
);
