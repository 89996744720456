import {
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import DialogTitle from '../../../components/common/Dialog/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface ChangePasswordDialogProps {
  open: boolean;
  onClose?: () => void;
  onChange: (password: string) => void;
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({ open, onClose, onChange }) => {
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChange = () => {
    onChange(password);
  };
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <DialogTitle title="Change password" onClose={onClose} />
      <DialogContent>
        <TextField
          variant="outlined"
          label="Password"
          className="mb-5"
          fullWidth
          id="password"
          name="mobile"
          type={passwordVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
                  {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleChange} disabled={!password}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
