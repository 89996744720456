import React from 'react';
import { Provider } from 'react-redux';
import { initStore } from './store';
import { createMuiTheme, MuiThemeProvider, StylesProvider, Theme } from '@material-ui/core';
import AppRoutes from './containers/AppRoutes';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateUtilProvider } from './context/DateUtilContext';
import { SnackbarProvider } from 'notistack';

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6C5DD3',
      dark: '#6C5DD3',
    },
    secondary: {
      main: '#F50057',
    },
    text: {
      primary: '#263238',
      secondary: '#546e7a',
    },
  },
});
function App() {
  const store = initStore();
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider maxSnack={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateUtilProvider value={{ format: 'dd/MM/yyyy', displayFormat: 'dd MMM yyyy' }}>
                <AppRoutes />
              </DateUtilProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
