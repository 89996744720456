import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Client, ClientUser } from '../../@types';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import { BreadcrumbsLink } from '../../components/common/Breadcrumbs/Breadcrumbs';
import Empty from '../../components/common/Empty';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import MainLayout from '../../components/MainLayout';
import { ROLE } from '../../enums/role';
import withAuth from '../../hocs/withAuth';
import withRoles from '../../hocs/withRoles';
import DeleteIcon from '@material-ui/icons/Delete';
import ClientUserService from '../../services/client-user.service';
import ClientService from '../../services/client.service';
import CreateClientUserDialog from '../../containers/ClientUser/CreateClientUserDialog';
import DeleteClientUserDialog from '../../containers/ClientUser/DeleteClientUserDialog';
import { Link, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Management',
    href: '/management/users',
  },
  {
    label: 'Brands',
    href: '/management/brands',
  },
];

const ClientUserUsers = () => {
  const { id } = useParams<{ id: string }>();
  const [clientUsers, setClientUsers] = useState<ClientUser[]>([]);
  const [client, setClient] = useState<Client | null>(null);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [clientUserId, setClientUserId] = useState<string | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const getAllClientUserUserByClientUserId = async () => {
    setLoading(true);
    const { clientUsers = [], error } = await ClientUserService.getAllClientUserByClientId(id);
    setLoading(false);
    if (error) {
    } else {
      setClientUsers(clientUsers);
    }
  };

  const handleCreateClientUserSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllClientUserUserByClientUserId();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (clientUserId: string) => () => {
      setClientUserId(clientUserId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    [],
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setClientUserId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteClientUserSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setClientUserId(null);
    getAllClientUserUserByClientUserId();
    // eslint-disable-next-line
  }, []);

  const getClientById = async () => {
    const { client, error } = await ClientService.getClientById(id);
    if (client) {
      setClient(client);
    } else if (error) {
    }
  };

  useEffect(() => {
    getAllClientUserUserByClientUserId();
    getClientById();
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
                {client?.name}'s clients
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setOpenCreateDialog(true)}
              >
                Add Client
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper className="mt-5" elevation={1}>
        <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
          <Empty isEmpty={clientUsers.length === 0} imgClassName="w-4/12">
            <TableContainer className="mt-2">
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientUsers.map((clientUser, index) => (
                    <TableRow key={clientUser.id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {clientUser.user?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {clientUser.user?.mobile}
                      </TableCell>
                      <TableCell className="font-bold" align="center">
                        <IconButton onClick={handleDeleteClick(clientUser.id)}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          component={Link}
                          to={`/management/users/${clientUser.user?.id}`}
                          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                            event.stopPropagation()
                          }
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Empty>
        </LoadingIndicator>
      </Paper>
      {id && (
        <CreateClientUserDialog
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          onSuccess={handleCreateClientUserSuccess}
          clientId={id}
        />
      )}

      {clientUserId && (
        <DeleteClientUserDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteClientUserSuccess}
          clientUserId={clientUserId}
          clientId={id}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(ClientUserUsers, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  '/login',
);
