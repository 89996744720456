import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserBasicDetailDTO } from '../../../@types/user';

interface PromoterDetailProps {
  promoter: UserBasicDetailDTO;
}
const PromoterDetail: React.FunctionComponent<PromoterDetailProps> = ({ promoter }) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Promoter detail</Typography>} />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">Name </TableCell>
            <TableCell>
              <Typography
                className="capitalize underline"
                component={Link}
                to={`/management/users/${promoter.id}`}
              >
                {promoter.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">obile </TableCell>
            <TableCell>
              <Typography className="capitalize">{promoter.mobile}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default PromoterDetail;
