import React from 'react';
import { Divider, Drawer, Toolbar } from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import { AuthState } from '../../store/auth/types';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SidebarProfile from './SidebarProfile';
import SideBarMenuList from './SideBarMenuList';
import { IMenuGroup } from '../../@types/sidebar';
import { ROLE } from '../../enums/role';
import { hasRole } from '../../util/role';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import EcoIcon from '@material-ui/icons/Eco';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import GroupIcon from '@material-ui/icons/Group';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const menuGroupList: IMenuGroup[] = [
  // {
  //   id: 'report',
  //   groupTitle: 'Report',
  //   roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
  //   menuList: [
  //     {
  //       title: 'Dashboard',
  //       path: '/dashboard',
  //       icon: DashboardIcon,
  //       roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
  //     },
  //   ],
  // },
  {
    id: 'management',
    groupTitle: 'Management',
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
    menuList: [
      {
        title: 'Users',
        path: '/management/users',
        icon: GroupIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
      {
        title: 'Roles',
        path: '/management/roles',
        icon: BeenhereIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
      {
        title: 'Modules',
        path: '/management/modules',
        icon: ViewModuleIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
      {
        title: 'Brands',
        path: '/management/brands',
        icon: SupervisedUserCircleIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
    ],
  },
  {
    id: 'branding',
    groupTitle: 'Branding',
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT],
    menuList: [
      {
        title: 'Activitity',
        path: '/branding/activities',
        icon: LocalActivityIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT],
      },
      {
        title: 'Branding Type',
        path: '/branding/branding-types',
        icon: EcoIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
      {
        title: 'Visibility Element',
        path: '/branding/visbility-elements',
        icon: ImageSearchIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
    ],
  },
];

const SideBarWrapper = styled(Drawer)`
  width: 240px;
  flex-shrink: 0;
  .sidebar-paper {
    width: 240px;
  }
  .profile-avatar {
    width: 80px;
    height: 80px;
  }
`;
interface SideBarProps {
  floatSideBar?: boolean;
}
const SideBar: React.FC<SideBarProps> = ({ floatSideBar }) => {
  const { pathname } = useLocation();
  const { authenticated, user } = useSelector<RootState, AuthState>((state) => state.authState);
  const filteredMenuList = menuGroupList.filter(
    (menuGroup) => user && hasRole(user.roles, menuGroup.roles),
  );
  return (
    <SideBarWrapper
      variant={floatSideBar ? 'temporary' : 'permanent'}
      className=""
      classes={{ paper: 'sidebar-paper' }}
    >
      <Toolbar />
      {user && <SidebarProfile user={user} />}
      <Divider />
      {user &&
        filteredMenuList.map((menuGroup) => (
          <SideBarMenuList
            menuList={menuGroup.menuList}
            isActive={(menu) => {
              return pathname.indexOf(menu.path) === 0;
            }}
            isVisible={() => true}
            title={menuGroup.groupTitle}
            key={menuGroup.id}
            user={user}
          />
        ))}
      {authenticated && <></>}
    </SideBarWrapper>
  );
};

export default SideBar;
