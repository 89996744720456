import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import DialogTitle from '../../components/common/Dialog/DialogTitle';
import VisibilityElementService from '../../services/visibility-element.service';

interface DeleteVisibilityElementDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  visibilityElementId: string;
}

const DeleteVisibilityElementDialog: React.FC<DeleteVisibilityElementDialogProps> = ({
  open,
  onClose,
  onSuccess,
  visibilityElementId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(async () => {
    const { message, error } = await VisibilityElementService.deleteVisibilityElement(visibilityElementId);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <DialogTitle title="Delete visibility element" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you user you want to delete the visibility element? The changes can't be reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteVisibilityElementDialog;
