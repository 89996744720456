import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Client } from '../../@types';
import ClientService from '../../services/client.service';
import ClientFormDialog from './ClientFormDialog';

interface EditClientDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  clientId: string;
}

const EditClientDialog: React.FC<EditClientDialogProps> = ({
  open,
  onClose,
  onSuccess,
  clientId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] = useState<Client | null>(null);

  const handleSave = useCallback(async (data: Client) => {
    const { message, error } = await ClientService.updateClient({
      ...data,
      id: clientId,
    });
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getClientById = async () => {
      const { client } = await ClientService.getClientById(clientId);
      if (client) {
        setClient(client);
      }
    };
    getClientById();
    // eslint-disable-next-line
  }, [clientId]);

  return (
    <>
      {client && (
        <ClientFormDialog
          mode="edit"
          client={client}
          open={open}
          onClose={onClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditClientDialog;
