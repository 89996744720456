import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import React from 'react';
import { User } from '../../../@types/user';

interface UserAddressCardProps {
  user: User;
}

const UserAddressCard: React.FunctionComponent<UserAddressCardProps> = ({ user }) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Address</Typography>} />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">Agency name</TableCell>
            <TableCell>{user?.address?.agencyName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Town</TableCell>
            <TableCell>{user?.address?.town}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell className="font-medium capitalize">State</TableCell>
            <TableCell>{user?.address?.state}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">City</TableCell>
            <TableCell>{user?.address?.city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Address</TableCell>
            <TableCell>{user?.address?.addressLine}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Pincode</TableCell>
            <TableCell>{user?.address?.pincode}</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserAddressCard;
