import React from 'react';
import { useSelector } from 'react-redux';
import { ROLE } from '../../../enums/role';
import { RootState } from '../../../store';
import { AuthState } from '../../../store/auth/types';
import { hasRole } from '../../../util/role';

interface CanViewProps {
  roles: ROLE[];
}

const CanView: React.FunctionComponent<CanViewProps> = ({ children, roles }) => {
  const { user } = useSelector<RootState, AuthState>((state) => state.authState);
  if (user && hasRole(user.roles, roles)) {
    return <>{children}</>;
  }
  return <></>;
};

export default CanView;
