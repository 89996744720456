import { Avatar, Box, Button, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsLink } from '../../components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from '../../components/MainLayout';
import withAuth from '../../hocs/withAuth';
import EditIcon from '@material-ui/icons/Edit';
import { User } from '../../@types/user';
import UserService from '../../services/user.service';

import Empty from '../../components/common/Empty';
import UserDetail from '../../containers/ViewUser/UserDetail';
import LoadingIndicator from '../../components/common/LoadingIndicator';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Management',
    href: '/management/users',
  },
  {
    label: 'User',
    href: '/management/users',
  },
];
const ViewUser = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  const getUserById = async (id: string) => {
    setLoading(true);
    const { user, error } = await UserService.getUserById(id);
    setLoading(false);
    if (error) {
    } else if (user) {
      setUser(user);
    }
  };
  const handleUpdate = () => {
    getUserById(id);
  };

  useEffect(() => {
    getUserById(id);
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <Box className="flex justify-between">
        <Box>
          <Breadcrumbs links={links} />
          {user && (
            <div className="flex items-center mt-3">
              <Avatar src={user?.imageUrl} alt={user?.name} className="mr-3" />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2 uppercase">
                {user?.name}
              </Typography>
            </div>
          )}
        </Box>
        {user && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              component={Link}
              to={`/management/users/${user?.id}/edit`}
              className="mr-2"
            >
              <EditIcon className="mr-2" />
              Edit
            </Button>
          </Box>
        )}
      </Box>
      <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
        <Empty isEmpty={!user} className="mt-8" imgClassName="w-4/12">
          <Tabs value={0} className="mt-3" indicatorColor="primary">
            <Tab label="Detail" />
          </Tabs>
          <Divider />
          <Box marginTop={3}>{user && <UserDetail user={user} onUpdate={handleUpdate} />}</Box>
        </Empty>
      </LoadingIndicator>
    </MainLayout>
  );
};

export default withAuth(ViewUser, '/login');
