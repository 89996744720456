import { ComponentType } from 'react';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import useSessionLoading from '../hooks/useSessionLoading';
import withConditionRedirect from './withConditonRedirect';

export default function withAuth<P = {}>(WrapperComponent: ComponentType<P>, location = '/login') {
  return withConditionRedirect({
    WrapperComponent,
    // eslint-disable-next-line
    redirectCondition: () => !useIsAuthenticated(),
     // eslint-disable-next-line
    isLoading:()=> useSessionLoading(),
    location,
  });
}
