import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface CircularProgressWithLabelProps {
  value: number;
}
const CircularProgressWithLabel: React.FunctionComponent<CircularProgressWithLabelProps> = ({
  value,
}) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" color="primary" value={value} size={80} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textPrimary"
          className="text-xl text-white"
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
