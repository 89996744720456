import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';

export interface BreadcrumbsLink {
  label: string;
  href?: string;
}
export interface BreadcrumbsProps {
  links: BreadcrumbsLink[];
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({ links }) => {
  return (
    <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {links.map((link) => (
        <Typography
          component={link.href ? Link : 'span'}
          to={link.href}
          className="hover:underline"
          key={link.label}
        >
          {link.label}
        </Typography>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
