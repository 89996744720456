import { Box, Typography, Grid, Backdrop } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BrandingActivity } from '../../@types/branding';
import Breadcrumbs, { BreadcrumbsLink } from '../../components/common/Breadcrumbs/Breadcrumbs';
import CircularProgressWithLabel from '../../components/common/Circular';
import Empty from '../../components/common/Empty';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import MainLayout from '../../components/MainLayout';
import BrandingActivityForm from '../../containers/BrandingActivity/BrandingActivityForm';
import { ROLE } from '../../enums/role';
import withAuth from '../../hocs/withAuth';
import withRoles from '../../hocs/withRoles';
import BrandingActivityService from '../../services/branding-activity.service';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Branding',
    href: '/branding/activities',
  },
  {
    label: 'Branding Activities',
    href: '/branding/activities',
  },
];
const EditBrandingActivity = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [brandingActivity, setBrandingActivity] = useState<BrandingActivity | null>();
  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);
  const [saving, setSaving] = useState(false);

  const getBrandingActivityById = async (id: string) => {
    setLoading(true);
    const { brandingActivity, error } = await BrandingActivityService.getBrandingActivityById(id);
    setLoading(false);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (brandingActivity) {
      setBrandingActivity(brandingActivity);
    }
  };

  useEffect(() => {
    getBrandingActivityById(id);
    // eslint-disable-next-line
  }, []);

  const handleSave = async (data: BrandingActivity) => {
    setSaving(true);
    const {
      brandingActivity: updatedBrandingActivity,
      error,
    } = await BrandingActivityService.updateBrandingActivity(
      {
        ...brandingActivity,
        ...data,
      },
      {
        onUploadProgress: (percent) => {
          setProgress(percent);
        },
      },
    );
    setSaving(false);
    setProgress(0);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (updatedBrandingActivity) {
      history.push(`/branding/activities/${updatedBrandingActivity.id}`);
    }
  };

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Breadcrumbs links={links} />
          <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
            Edit BrandingActivity
          </Typography>
          <Box marginTop={2}>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={!brandingActivity} imgClassName="w-4/12">
                {brandingActivity && (
                  <BrandingActivityForm
                    mode="edit"
                    brandingActivity={brandingActivity}
                    onSave={handleSave}
                  />
                )}
              </Empty>
            </LoadingIndicator>
          </Box>
        </Grid>
      </Grid>
      <Backdrop open={saving} style={{ zIndex: 2000 }} className="flex-col">
        <CircularProgressWithLabel value={progress} />
        <Typography variant="h6" className="text-white">
          uploading
        </Typography>
      </Backdrop>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(EditBrandingActivity, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  '/login',
);
