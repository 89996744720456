import { Role } from '../@types/user';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';

interface GetAllRoleResult {
  roles?: Role[];
  error?: string;
}

interface GetAllRoleResponse {
  roles?: Role[];
}

const getAllRole = async () => {
  const result: GetAllRoleResult = {};
  try {
    const response = await API.get<GetAllRoleResponse>('/roles');
    result.roles = response.data.roles;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const RoleService = {
  getAllRole,
};

export default RoleService;
