import Joi from 'joi';

const loginFormSchema = Joi.object({
  mobile: Joi.string().required().messages({
    'string.empty': 'Mobile number should not be empty',
  }),
  password: Joi.string().required().messages({
    'string.empty': 'Password should not be empty',
  }),
});

export default loginFormSchema;
