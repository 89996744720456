import {
  AuthActionTypes,
  AuthState,
  LOGOUT,
  SESSION_LOAD_ERROR,
  SESSION_LOAD_SUCCESS,
  SET_USER,
  START_SESSION_LOADING,
} from './types';

const initialState: AuthState = {
  user: null,
  authenticated: false,
  sessionLoading: true,
};

export default function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        authenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        authenticated: false,
      };
    case START_SESSION_LOADING:
      return {
        ...state,
        sessionLoading: true,
      };
    case SESSION_LOAD_SUCCESS:
      return {
        ...state,
        sessionLoading: false,
        user: action.payload.user,
        authenticated: true,
      };
    case SESSION_LOAD_ERROR:
      return {
        ...state,
        sessionLoading: false,
        authenticated: false,
      };
    default:
      return { ...state };
  }
}
