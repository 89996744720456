import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import DateUtilContext from '../../context/DateUtilContext';
import { format } from 'date-fns';

interface DisplayDateProps {
  date: Date;
}

const DisplayDate: React.FunctionComponent<DisplayDateProps> = ({ date }) => {
  const { displayFormat } = useContext(DateUtilContext);
  return <Typography component="span" variant="body2">{format(date, displayFormat)}</Typography>;
};

export default DisplayDate;
