import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { BrandingType } from '../../@types/branding';
import BrandingTypeService from '../../services/branding-type.service';
import BrandingTypeFormDialog from './BrandingTypeFormDialog';

interface CreateBrandingTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const CreateBrandingTypeDialog: React.FC<CreateBrandingTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [brandingType] = useState<BrandingType>({ id: '', name: '' });

  const handleSave = useCallback(async (data: BrandingType) => {
    const { message, error } = await BrandingTypeService.createBrandingType(data);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BrandingTypeFormDialog
      mode="create"
      brandingType={brandingType}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateBrandingTypeDialog;
