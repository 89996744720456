import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import DialogTitle from '../../components/common/Dialog/DialogTitle';
import ClientUserService from '../../services/client-user.service';

interface DeleteClientUserDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  clientId: string;
  clientUserId: string;
}

const DeleteClientUserDialog: React.FC<DeleteClientUserDialogProps> = ({
  open,
  onClose,
  onSuccess,
  clientUserId,
  clientId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(async () => {
    const { message, error } = await ClientUserService.deleteClientUser(clientUserId, clientId);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <DialogTitle title="Remove Client" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you user you want to remove the client? The changes can't be reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleDelete}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteClientUserDialog;
