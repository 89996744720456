import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { VisibilityElement } from '../../@types/branding';
import VisibilityElementService from '../../services/visibility-element.service';
import VisibilityElementFormDialog from './VisibilityElementFormDialog';

interface CreateVisibilityElementDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const CreateVisibilityElementDialog: React.FC<CreateVisibilityElementDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [visibilityElement] = useState<VisibilityElement>({ id: '', name: '', brandingTypeId: '' });

  const handleSave = useCallback(async (data: VisibilityElement) => {
    const { message, error } = await VisibilityElementService.createVisibilityElement(data);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <VisibilityElementFormDialog
      mode="create"
      visibilityElement={visibilityElement}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateVisibilityElementDialog;
