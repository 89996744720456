import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Client } from '../../@types/client';
import DialogTitle from '../../components/common/Dialog/DialogTitle';
import { clientFormSchema } from '../../validation/client';

interface ClientFormProps {
  mode: 'create' | 'edit';
  open: boolean;
  client: Client;
  onSave?: (client: Client) => void;
  onClose?: () => void;
}

const ClientFormDialog: React.FC<ClientFormProps> = ({
  mode,
  client,
  onSave,
  open,
  onClose,
}) => {
  const { register, errors, handleSubmit } = useForm<Client>({
    defaultValues: client,
    mode: 'onSubmit',
    resolver: joiResolver(clientFormSchema),
  });

  const onSubmit = (data: Client) => {
    onSave?.(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          title={mode === 'create' ? 'Add Brand' : 'Edit Brand'}
          onClose={onClose}
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Name"
                className="w-full"
                id="name"
                name="name"
                error={!!errors.name}
                helperText={errors.name?.message}
                inputRef={register}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {mode === 'create' ? 'Create' : 'Save '}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ClientFormDialog;
