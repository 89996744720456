import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsLink } from '../components/common/Breadcrumbs/Breadcrumbs';
import Empty from '../components/common/Empty';
import LoadingIndicator from '../components/common/LoadingIndicator';
import MainLayout from '../components/MainLayout';
import { ROLE } from '../enums/role';
import withAuth from '../hocs/withAuth';
import withRoles from '../hocs/withRoles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BrandingTypeService from '../services/branding-type.service';
import { BrandingType } from '../@types/branding';
import CreateBrandingTypeDialog from '../containers/BrandingType/CreateBrandingTypeDialog';
import EditBrandingTypeDialog from '../containers/BrandingType/EditingBrandingTypeDialog';
import DeleteBrandingTypeDialog from '../containers/BrandingType/DeleteBrandingTypeDialog';
import { BrandingTypeFilterOption } from '../@types';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Branding',
    href: '/branding/branding-types',
  },
  {
    label: 'Branding Types',
  },
];

const BrandingTypes = () => {
  const [brandingTypes, setBrandingTypes] = useState<BrandingType[]>([]);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [brandingTypeId, setBrandingTypeId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterOption, setFilterOption] = useState<BrandingTypeFilterOption>({
    query: '',
    page: 1,
    size: 20,
    filterType: 'filter',
  });
  const rowsPerPageOptions = [10, 20, 30, 40, 50];
  const [brandingTypeCount, setBrandingTypeCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setFilterOption({ ...filterOption, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOption({ ...filterOption, page: 1, size: parseInt(event.target.value, 10) });
  };

  const getAllBrandingType = async () => {
    setLoading(true);
    const { brandingTypes = [], count = 0, error } = await BrandingTypeService.getAllBrandingType(
      filterOption,
    );
    setBrandingTypeCount(count);
    setLoading(false);
    if (error) {
    } else {
      setBrandingTypes(brandingTypes);
    }
  };

  const handleCreateBrandinTypeSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllBrandingType();
    // eslint-disable-next-line
  }, []);

  const handleEditClick = useCallback(
    (brandingTypeId: string) => () => {
      setBrandingTypeId(brandingTypeId);
      setOpenEditDialog(true);
    },
    // eslint-disable-next-line
    [],
  );

  const handleEditDialogClose = useCallback(() => {
    setOpenEditDialog(false);
    setBrandingTypeId(null);
    // eslint-disable-next-line
  }, []);

  const handleEditBrandinTypeSuccess = useCallback(() => {
    setOpenEditDialog(false);
    setBrandingTypeId(null);
    getAllBrandingType();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (brandingTypeId: string) => () => {
      setBrandingTypeId(brandingTypeId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    [],
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setBrandingTypeId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteBrandinTypeSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setBrandingTypeId(null);
    getAllBrandingType();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllBrandingType();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
          <Grid container justify="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
                All Branding Type
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setOpenCreateDialog(true)}
              >
                New Branding Type
              </Button>
            </Grid>
          </Grid>
          <Paper className="mt-5" elevation={1}>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={brandingTypes.length === 0} imgClassName="w-4/12">
                <TableContainer className="mt-2">
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {brandingTypes.map((brandingType, index) => (
                        <TableRow key={brandingType.id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {brandingType.name}
                          </TableCell>
                          <TableCell className="font-bold" align="center">
                            <IconButton onClick={handleEditClick(brandingType.id)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={handleDeleteClick(brandingType.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={brandingTypeCount}
                  rowsPerPage={filterOption.size}
                  page={filterOption.page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Empty>
            </LoadingIndicator>
          </Paper>
        </Grid>
      </Grid>
      <CreateBrandingTypeDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={handleCreateBrandinTypeSuccess}
      />
      {brandingTypeId && (
        <EditBrandingTypeDialog
          open={openEditDialog}
          onClose={handleEditDialogClose}
          onSuccess={handleEditBrandinTypeSuccess}
          brandingTypeId={brandingTypeId}
        />
      )}
      {brandingTypeId && (
        <DeleteBrandingTypeDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteBrandinTypeSuccess}
          brandingTypeId={brandingTypeId}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(BrandingTypes, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  '/login',
);
