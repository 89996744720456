import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import React from 'react';
import DialogTitle from '../../../components/common/Dialog/DialogTitle';

interface DeleteUserDialogProps {
  open: boolean;
  onClose?: () => void;
  onDelete: () => void;
}

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({ open, onClose, onDelete }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <DialogTitle title="Delete user" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you user you want to delete the user? The changes can't be reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;
