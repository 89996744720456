import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Client } from '../../@types';
import ClientService from '../../services/client.service';
import ClientFormDialog from './ClientFormDialog';

interface CreateClientDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const CreateClientDialog: React.FC<CreateClientDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [client] = useState<Client>({ id: '', name: '' });

  const handleSave = useCallback(async (data: Client) => {
    const { message, error } = await ClientService.createClient(data);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ClientFormDialog
      mode="create"
      client={client}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateClientDialog;
