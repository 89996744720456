import React from 'react';

const Download = ({ ...props }) => {
  return (
    <svg
      id="bc1f3139-0466-4655-9cee-0348334ffa8f"
      data-name="Layer 1"
      width="977.47786"
      height="801.93613"
      viewBox="0 0 977.47786 801.93613"
      {...props}
    >
      <defs>
        <linearGradient
          id="a7d70b19-b507-4394-a133-55f51a2376ed"
          x1="323.00662"
          y1="814.32902"
          x2="323.00662"
          y2="813.88035"
          gradientTransform="matrix(-1, 0, 0, 1, 651, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
      </defs>
      <title>download</title>
      <path
        d="M736.91966,127.882v610.02a27.40545,27.40545,0,0,1-27.41,27.4h-247.66a27.40545,27.40545,0,0,1-27.41-27.4V127.882a27.40759,27.40759,0,0,1,27.41-27.41h37.06V105.232a22.57647,22.57647,0,0,0,22.57,22.57h125.95a22.57,22.57,0,0,0,22.57-22.57V100.472h39.51A27.40759,27.40759,0,0,1,736.91966,127.882Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#f2f2f2"
      />
      <path
        d="M774.23126,223.30777h2.45455a2.29166,2.29166,0,0,1,2.29166,2.29166v80.43684a2.29167,2.29167,0,0,1-2.29166,2.29167h-2.45455"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M429.12694,184.69093H427.3524a1.50327,1.50327,0,0,1-1.50327-1.50327V158.20489a1.50327,1.50327,0,0,1,1.50327-1.50327h1.77454"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M428.848,256.66345h-1.5147a1.69459,1.69459,0,0,1-1.69459-1.69459v-45.329a1.69459,1.69459,0,0,1,1.69459-1.6946h1.79366"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M428.848,322.84871h-1.49148a1.61261,1.61261,0,0,1-1.6126-1.6126V275.32217a1.6126,1.6126,0,0,1,1.6126-1.61259H428.848"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <rect
        x="317.6162"
        y="15.42306"
        width="345.10432"
        height="701.37379"
        rx="35.69395"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <rect
        x="454.87844"
        y="39.36805"
        width="48.19213"
        height="9.78574"
        rx="2.54189"
        fill="#f2f2f2"
      />
      <circle cx="515.90777" cy="44.26092" r="5.55051" fill="#f2f2f2" />
      <rect
        x="456.87844"
        y="36.36805"
        width="48.19213"
        height="9.78574"
        rx="2.54189"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <circle
        cx="517.90777"
        cy="41.26092"
        r="5.55051"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M752.91966,109.882v610.02a27.40545,27.40545,0,0,1-27.41,27.4h-247.66a27.40545,27.40545,0,0,1-27.41-27.4V109.882a27.40759,27.40759,0,0,1,27.41-27.41h37.06V87.232a22.57647,22.57647,0,0,0,22.57,22.57h125.95a22.57,22.57,0,0,0,22.57-22.57V82.472h39.51A27.40759,27.40759,0,0,1,752.91966,109.882Z"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <rect
        x="347.57891"
        y="91.99008"
        width="31"
        height="4"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <rect
        x="347.57891"
        y="98.99008"
        width="31"
        height="4"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <rect
        x="347.57891"
        y="105.99008"
        width="31"
        height="4"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M738.75456,150.772h-.91824l-.34477-.28784a7.62034,7.62034,0,0,0,1.77982-4.893,7.43383,7.43383,0,1,0-7.405,7.48343,7.6931,7.6931,0,0,0,4.87907-1.78434l.34424.2879v.92086l5.74018,5.75644,1.722-1.72693Zm-6.88823,0a5.18086,5.18086,0,1,1,5.16612-5.18086A5.15205,5.15205,0,0,1,731.86633,150.772Z"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M906.66523,217.77107a38.95007,38.95007,0,0,1,38.89611-36.91443c1.18377,0,2.35271.06174,3.50958.16513a51.74974,51.74974,0,0,1,89.45652-.04229c.976-.07331,1.95855-.12284,2.95327-.12284a38.95007,38.95007,0,0,1,38.89611,36.91443"
        transform="translate(-111.51074 -48.78194)"
        fill="#f2f2f2"
      />
      <path
        d="M914.77767,214.77486a38.95008,38.95008,0,0,1,38.89611-36.91442c1.18377,0,2.35271.06173,3.50959.16513a51.74974,51.74974,0,0,1,89.45651-.0423c.976-.0733,1.95855-.12283,2.95328-.12283a38.95007,38.95007,0,0,1,38.8961,36.91442"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M802.66523,438.77107a38.95007,38.95007,0,0,1,38.89611-36.91443c1.18377,0,2.35271.06174,3.50958.16513a51.74974,51.74974,0,0,1,89.45652-.04229c.976-.07331,1.95855-.12284,2.95327-.12284a38.95007,38.95007,0,0,1,38.89611,36.91443"
        transform="translate(-111.51074 -48.78194)"
        fill="#f2f2f2"
      />
      <path
        d="M810.77767,435.77486a38.95008,38.95008,0,0,1,38.89611-36.91442c1.18377,0,2.35271.06173,3.50959.16513a51.74974,51.74974,0,0,1,89.45651-.0423c.976-.0733,1.95855-.12283,2.95328-.12283a38.95007,38.95007,0,0,1,38.8961,36.91442"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M151.66523,114.77107a38.95007,38.95007,0,0,1,38.89611-36.91443c1.18377,0,2.35271.06174,3.50958.16513a51.74974,51.74974,0,0,1,89.45652-.04229c.976-.07331,1.95855-.12284,2.95327-.12284a38.95007,38.95007,0,0,1,38.89611,36.91443"
        transform="translate(-111.51074 -48.78194)"
        fill="#f2f2f2"
      />
      <path
        d="M159.77767,111.77486a38.95008,38.95008,0,0,1,38.89611-36.91442c1.18377,0,2.35271.06173,3.50959.16513a51.74974,51.74974,0,0,1,89.45651-.0423c.976-.0733,1.95855-.12283,2.95328-.12283a38.95007,38.95007,0,0,1,38.8961,36.91442"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <rect x="349.07891" y="194" width="238" height="132" rx="12.54724" fill="#6c63ff" />
      <rect
        x="362.07891"
        y="169"
        width="238"
        height="132"
        rx="12.54724"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M555.05822,313.30129l20.99113,21.6334a4.77133,4.77133,0,0,0,3.509,1.40985h.06266a4.77133,4.77133,0,0,0,3.509-1.40985l20.99113-21.6334a4.63225,4.63225,0,0,0,0-6.76729,5.18146,5.18146,0,0,0-7.08059,0l-12.438,13.002V286.01282a5.01782,5.01782,0,0,0-10.02561,0V319.536l-12.438-13.002a5.18144,5.18144,0,0,0-7.08059,0,4.63225,4.63225,0,0,0,0,6.76729Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#fff"
      />
      <rect
        x="486.58964"
        y="468.78194"
        width="238"
        height="132"
        rx="12.54724"
        transform="translate(1099.66855 1020.78194) rotate(-180)"
        fill="#6c63ff"
      />
      <rect
        x="473.58964"
        y="443.78194"
        width="238"
        height="132"
        rx="12.54724"
        transform="translate(1073.66855 970.78194) rotate(-180)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M630.12107,539.3013a4.63225,4.63225,0,0,0,0-6.76729,5.18144,5.18144,0,0,0-7.08059,0l-12.438,13.002V512.01282a5.01782,5.01782,0,0,0-10.02561,0V545.536l-12.438-13.002a5.18146,5.18146,0,0,0-7.08059,0,4.63225,4.63225,0,0,0,0,6.76729l20.99113,21.6334a4.77131,4.77131,0,0,0,3.509,1.40985h.06266a4.77133,4.77133,0,0,0,3.509-1.40985l20.99113-21.6334Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#fff"
      />
      <path
        d="M328.41943,813.88035l-.00036.00132s-.31446.17476-.85173.44735Q328.00131,814.10425,328.41943,813.88035Z"
        transform="translate(-111.51074 -48.78194)"
        fill="url(#a7d70b19-b507-4394-a133-55f51a2376ed)"
      />
      <path
        d="M209.15378,709.08421c-7.50261,21.57,1.252,56.58375,5.3174,72.2126,4.06086,15.63357,5.31282,34.39008,5.31282,34.39008,15.943,2.49932,27.51109-.62835,27.51109-.62835L240.73031,782.863c4.68913-19.07068,0-70.02747,0-70.02747s10.62564-19.69435,11.56346-49.70478c.92375-29.54151,8.206-39.08857,8.43574-39.384l.0047-.0047c15.00521,7.8168,34.07586,91.59747,34.07586,91.59747-1.56151,20.318.31414,51.58043,6.56478,68.46129a84.75941,84.75941,0,0,1,4.68913,33.44757c2.1851,1.84752,5.2237,2.16168,8.50609,1.63181,8.92807-1.44426,19.62869-9.13441,19.62869-9.13441s-3.43717-91.28331-5.94109-94.72042c-2.49934-3.43714,0-23.13148,0-23.13148l-4.06086-31.88609s.62369-54.08443-5.3174-63.46269c-5.12524-8.0981-6.30691-22.32963-6.53667-26.06685-.0328-.59084-.04688-.91908-.04688-.91908l-9.04535-10.83657s-87.532-6.56478-94.09673,0c-1.61309,1.61306-2.60718,5.27527-3.198,9.88938-1.80536,14.17993.26262,37.31138.26262,37.31138s2.62585,17.19506,6.37715,34.69956a76.00815,76.00815,0,0,1,0,31.88608A95.36238,95.36238,0,0,1,209.15378,709.08421Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#575a89"
      />
      <path
        d="M214.626,846.16621c3.90608,5.158,26.88281,2.96825,30.79348,1.35991,2.3868-.98476,2.20861-5.47692,1.56151-10.10509-.40795-2.94479-1.00817-5.94584-1.252-8.13569-.62369-5.62693,1.87565-17.04028,1.87565-17.04028s-5.158,5.47224-6.40542,0c-.54773-2.392-2.47139-3.61818-4.46219-4.24074a11.67771,11.67771,0,0,0-8.11578.509c-4.0596,1.76022-10.83558,5.51663-9.6203,10.59667,1.72088,7.17909-4.375,17.36386-4.375,17.36386a7.81407,7.81407,0,0,0-1.13943,1.96472A7.51005,7.51005,0,0,0,214.626,846.16621Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#cbceda"
      />
      <path
        d="M214.626,846.16621c3.90608,5.158,26.88281,2.96825,30.79348,1.35991,2.3868-.98476,2.20861-5.47692,1.56151-10.10509-2.46672,1.15479-6.66321,2.11353-13.658,1.46667a39.31042,39.31042,0,0,0-4.91278-.15072,114.95661,114.95661,0,0,1-14.92361-.29845A7.51005,7.51005,0,0,0,214.626,846.16621Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M294.81007,715.33952c-1.56151,20.318.31414,51.58043,6.56478,68.46129a84.75941,84.75941,0,0,1,4.68913,33.44757c2.1851,1.84752,5.2237,2.16168,8.50609,1.63181-1.885-13.18583-9.92221-69.99464-15.07087-114.87429-5.86141-51.11151-33.29282-86.28-33.29282-86.28l-5.47217,6.01613C275.73942,631.55885,294.81007,715.33952,294.81007,715.33952Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M224.00422,386.78629l3.817,18.75652L246.512,436.96l23.05641-9.84717s31.33749-32.82391,11.64315-37.513a17.33077,17.33077,0,0,1-6.54131-2.912c-3.86384-2.91662-5.08306-7.15561-5.10184-11.30548a34.63938,34.63938,0,0,1,3.20271-13.44842l-40.32651-12.66065a64.4,64.4,0,0,1,2.89316,16.33691c.30955,10.982-3.77,16.39321-7.11807,18.97223A12.11163,12.11163,0,0,1,224.00422,386.78629Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#fbbebe"
      />
      <path
        d="M232.44465,349.27326a64.4,64.4,0,0,1,2.89316,16.33691,30.994,30.994,0,0,0,2.34456,2.49462,31.9113,31.9113,0,0,0,31.88608,7.27754,34.63938,34.63938,0,0,1,3.20271-13.44842Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M227.82118,344.11522a31.88492,31.88492,0,1,0,31.88608-31.88609A31.76788,31.76788,0,0,0,227.82118,344.11522Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#fbbebe"
      />
      <path
        d="M224.00422,386.78629l3.817,18.75652L246.512,436.96l23.05641-9.84717s31.33749-32.82391,11.64315-37.513a17.33077,17.33077,0,0,1-6.54131-2.912c3.559,9.77216-11.12261,30.10891-11.12261,30.10891-1.7069,2.51337-5.627.93783-5.627.93783-9.45336-1.43018-26.864-28.62713-29.701-33.15215A12.11163,12.11163,0,0,1,224.00422,386.78629Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M205.95576,568.612c5.54728,1.26138,36.26108,8.06528,47.90411,6.36313,12.81543-1.87565,37.73347-3.12295,37.73347-3.12295s15.868.85812,20.7494-1.37391c-.0328-.59084-.04688-.91908-.04688-.91908l-9.04535-10.83657s-87.532-6.56478-94.09673,0C207.54069,560.33566,206.5466,563.99787,205.95576,568.612Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M263.54921,417.73455s16.49322-22.85,10.27857-31.74456l27.85968,11.425s16.76883-2.03259,22.82,22.99209a43.2943,43.2943,0,0,1,1.03593,12.70146c-.2198,3.77329.29307,10.41622,4.59144,19.013,6.87739,13.75478,5.31435,45.32825-8.753,48.45434s-19.06913,1.563-19.06913,1.563,1.87565,57.20738,10.00348,63.77216-20.72319,5.00174-20.72319,5.00174-24.91767,1.25043-37.73463,3.12609-48.76694-6.56479-48.76694-6.56479,5.31434-24.07086,7.19-39.38868,6.25218-47.935,6.25218-47.935L186.64749,436.96s-5.627-26.10282,14.06738-37.66934,27.10594-14.41034,27.10594-14.41034,19.78536,32.229,30.10144,33.79207C257.92225,418.67237,261.84111,420.247,263.54921,417.73455Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#3f3d56"
      />
      <path
        d="M300.12442,426.8002s.31261,10.31608-1.563,14.38-8.753,26.57173-8.753,26.57173h15.63043s-8.44044-9.06565-7.81522-13.44217S300.12442,426.8002,300.12442,426.8002Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M253.85834,504.63975s34.51754-8.44043,37.73463-15.63043,12.08431-23.27994,12.08431-23.27994l-3.6435-4.90768-16.79023,13.1824s-20.007,15.943-29.38521,17.50609S253.85834,504.63975,253.85834,504.63975Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#fbbebe"
      />
      <path
        d="M253.85834,504.63975s34.51754-8.44043,37.73463-15.63043,12.08431-23.27994,12.08431-23.27994l-3.6435-4.90768-16.79023,13.1824s-20.007,15.943-29.38521,17.50609S253.85834,504.63975,253.85834,504.63975Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.05"
      />
      <path
        d="M224.00422,464.00218v18.72839h3.01038a87.60925,87.60925,0,0,1,42.507,11.0007l5.641,3.13234,1.51933.844,9.6877-21.8279-9.06412-8.12627s-.164.01875-.46422.04222c-2.21795.21568-11.8072,1.01753-15.87264-.35638-3.8592-1.30828-8.65617-5.46754-20.02731-2.56027-5.59885,1.43017-10.38174,7.70893-15.81642,5.74887Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#fbbebe"
      />
      <path
        d="M193.83748,429.92629,187.58531,436.96v17.03717s5.31435,53.76868,22.19522,59.70825,47.01551-3.4387,47.01551-3.4387,7.78284-20.63217,4.14192-21.8826-32.17932-8.78853-32.17932-8.78853.40362-31.538-5.84855-40.91624c0,0,4.06391-29.38521-10.00348-30.01043S193.83748,429.92629,193.83748,429.92629Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M192.89966,429.92629,186.64749,436.96v17.03717s5.31434,53.76868,22.19521,59.70825,47.01551-3.4387,47.01551-3.4387,7.78285-20.63217,4.14192-21.8826-32.17932-8.78853-32.17932-8.78853.40362-31.538-5.84855-40.91624c0,0,4.06391-29.38521-10.00347-30.01043S192.89966,429.92629,192.89966,429.92629Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#3f3d56"
      />
      <path
        d="M236.56641,357.66678a12.48011,12.48011,0,0,1,.71276-6.08648c.8815-1.86626,2.94479-3.33866,4.95639-2.865,2.67753.62366,3.831,4.10768,6.45224,4.94233,2.20856.70337,4.62348-.86278,5.67853-2.9307,1.05506-2.0632,1.08322-4.48281,1.08791-6.79924q.00705-3.91773.01872-7.83552c.00469-2.84161.08912-5.913,1.84285-8.145,2.1804-2.78065,7.301-3.99515,10.71935-3.88262a9.06655,9.06655,0,0,1,6.11928,2.46649c1.83346,1.55679,3.94826,2.70094,6.44285,2.293a5.56289,5.56289,0,0,0,4.48753-3.88261.74891.74891,0,0,0,.02347-.09846A31.88386,31.88386,0,1,0,237.68237,367.167,86.17382,86.17382,0,0,1,236.56641,357.66678Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M280.59819,327.8867a5.57013,5.57013,0,0,0,4.48868-3.88363c.26277-1.00923.19378-2.08595.49087-3.08563.72987-2.456,3.35193-3.73664,5.10939-5.601,3.52273-3.737,3.29388-9.88525.80025-14.37486-2.01246-3.62329-5.9165-6.68277-10.0002-5.97456-2.52823.43846-4.71565,2.24069-7.27649,2.403-2.46538.15625-4.72281-1.22478-6.9702-2.25035a26.27874,26.27874,0,0,0-17.28014-1.53364,9.95578,9.95578,0,0,0-4.7546,2.39808,40.49992,40.49992,0,0,0-2.53626,3.565c-2.91619,3.71368-8.13178,4.43893-12.77774,5.28192s-9.904,2.67643-11.431,7.14453c-1.01248,2.96262-.03034,6.18027.5514,9.2566a2.7207,2.7207,0,0,1-.124,1.911c-.72469,1.1988-2.53916.69713-3.91012.98483-2.636.55315-3.31345,3.94914-3.41048,6.64078q-.16657,4.6209-.251,9.24428a11.95061,11.95061,0,0,0,.27282,3.4307,12.12149,12.12149,0,0,0,2.65863,4.30246l10.32256,12.40088c4.57684,5.49834,9.43745,11.90706,8.51506,19.00132,2.16944.97347,4.74556-.89925,5.457-3.16818s.13686-4.71336-.34515-7.04184a86.57534,86.57534,0,0,1-1.632-12.20814,12.43913,12.43913,0,0,1,.71577-6.086c.87906-1.86884,2.94475-3.33839,4.95576-2.8681,2.67772.62619,3.82987,4.10859,6.45,4.94372,2.20874.704,4.62644-.8646,5.67966-2.92977s1.08142-4.48233,1.0869-6.80055l.0185-7.83578c.0067-2.83917.09052-5.91266,1.84278-8.14658,2.18022-2.7795,7.30318-3.99368,10.72225-3.88259a9.07916,9.07916,0,0,1,6.11684,2.46757C275.98838,327.14949,278.1011,328.29349,280.59819,327.8867Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#3f3d56"
      />
      <path
        d="M273.39638,426.01868l-9.78975,40.21522S255.8903,466.18889,273.39638,426.01868Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M239.94726,419.29759s12.81695,20.99063,7.81521,42.694l-2.38793.17446S249.63813,452.1215,239.94726,419.29759Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M231.81943,538.08887s-15.73783-5.20547-16.88086,6.87739C214.93857,544.96626,217.90835,536.68213,231.81943,538.08887Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M277.304,468.68976s3.12609,21.8826-2.50087,30.63564c0,0,11.44573,4.68913,27.50956,3.75131l1.87565-34.387Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M275.16261,496.86361l1.51933.844,9.6877-21.8279-9.06412-8.12627h-.46892a.28883.28883,0,0,0,.0047.04222C276.982,468.80385,279.50944,487.59788,275.16261,496.86361Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M277.304,467.75193s3.12609,21.8826-2.50087,30.63565c0,0,11.44573,4.68913,27.50956,3.7513l10.9413-34.38695Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#3f3d56"
      />
      <path
        d="M302.34272,840.85467a7.07925,7.07925,0,0,0,6.0706,6.19285c8.48992,1.26827,28.93735-2.49741,34.10153-.04073,6.56478,3.12767,32.50977.93783,33.76173-2.18515a4.93352,4.93352,0,0,0-.45014-3.76069,9.98125,9.98125,0,0,0-4.19211-4.50619,10.38642,10.38642,0,0,0-1.88029-.83468c-4.88615-1.6318-14.119-5.09244-16.609-8.71713,0,0-11.50477-10.06664-14.78839-15.51105a7.50969,7.50969,0,0,0-6.01019-3.74308c-2.73846-.1225-5.78874,1.25493-7.036,7.25466,0,0-14.99583,8.55767-19.05669,1.05506,0,0-.62358,18.52586-3.11353,21.677-.00469.00469-.00939.014-.01408.01871A4.161,4.161,0,0,0,302.34272,840.85467Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#cbceda"
      />
      <path
        d="M302.34272,840.85467a7.07925,7.07925,0,0,0,6.0706,6.19285c8.48992,1.26827,28.93735-2.49741,34.10153-.04073,6.56478,3.12767,32.50977.93783,33.76173-2.18515a4.93352,4.93352,0,0,0-.45014-3.76069,9.98125,9.98125,0,0,0-4.19211-4.50619c-4.22955,2.80409-13.62189,5.55192-34.27753,2.32579-19.70373-3.07609-29.45714.90883-34.21656-1.145-.00469.00469-.00939.014-.01408.01871A4.161,4.161,0,0,0,302.34272,840.85467Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <g opacity="0.1">
        <path
          d="M219.04105,321.67135c-.00882-.05014-.0174-.10054-.02679-.15-.443-2.34276-1.11573-4.76718-.9653-7.09881a45.60409,45.60409,0,0,0,.9653,5.69207A3.38839,3.38839,0,0,1,219.04105,321.67135Z"
          transform="translate(-111.51074 -48.78194)"
        />
        <path
          d="M237.2832,350.93339a9.1354,9.1354,0,0,0-.76759,3.57228,10.76385,10.76385,0,0,1,.76759-4.979c.8791-1.86884,2.94479-3.33837,4.95576-2.86811,2.6777.6262,3.82984,4.10859,6.45,4.94373,2.20879.704,4.62651-.86462,5.67973-2.92976s1.08138-4.48235,1.08688-6.80056q.00927-3.9179.01843-7.83577c.00675-2.83918.09055-5.91267,1.84279-8.14659,2.18029-2.77951,7.30319-3.99369,10.72227-3.88258a9.07894,9.07894,0,0,1,6.11682,2.46757c1.83466,1.55734,3.94735,2.70132,6.44446,2.29454a5.57021,5.57021,0,0,0,4.48867-3.88362c.26273-1.00923.19376-2.08595.49089-3.08563.72982-2.456,3.35188-3.73665,5.10939-5.601a10.23456,10.23456,0,0,0,2.63678-6.6074,10.65727,10.65727,0,0,1-2.63678,8.01414c-1.75751,1.86437-4.37957,3.145-5.10939,5.601-.29713.99968-.22816,2.0764-.49089,3.08563a5.57021,5.57021,0,0,1-4.48867,3.88362c-2.49711.40677-4.6098-.7372-6.44446-2.29454a9.079,9.079,0,0,0-6.11682-2.46758c-3.41908-.1111-8.542,1.10308-10.72227,3.88259-1.75224,2.23392-1.836,5.30741-1.84279,8.14659q-.00911,3.91785-.01843,7.83577c-.0055,2.31821-.03366,4.73538-1.08688,6.80056s-3.47094,3.63379-5.67973,2.92976c-2.62012-.83514-3.77226-4.31753-6.45-4.94374C240.228,347.595,238.1623,349.06455,237.2832,350.93339Z"
          transform="translate(-111.51074 -48.78194)"
        />
        <path
          d="M211.59145,342.32619a12.12287,12.12287,0,0,0,2.65858,4.30247q5.16124,6.20041,10.32261,12.40086c4.34546,5.22043,8.943,11.262,8.60758,17.9314-.31345-6.11379-4.56962-11.67371-8.60758-16.52466q-5.16125-6.20041-10.32261-12.40087a12.12269,12.12269,0,0,1-2.65858-4.30247,11.94551,11.94551,0,0,1-.27281-3.42949A8.50743,8.50743,0,0,0,211.59145,342.32619Z"
          transform="translate(-111.51074 -48.78194)"
        />
        <path
          d="M238.54467,374.86269a7.34444,7.34444,0,0,0,.30864-1.67277,8.3752,8.3752,0,0,1-.30864,3.07951c-.71139,2.26892-3.28755,4.14162-5.457,3.16816a13.61224,13.61224,0,0,0,.107-1.36773C235.34127,378.937,237.84485,377.09469,238.54467,374.86269Z"
          transform="translate(-111.51074 -48.78194)"
        />
      </g>
      <path
        d="M340.41866,814.32139s-3.26664,5.74843-15.1082.68209"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M242.31847,814.7062s-10.21432,12.02272-23.08215,3.94222"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M212.59553,672.509s7.21512,4.5073,7.44958,7.08633-3.72479-1.87566-3.72479-1.87566Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M209.74816,707.07414a15.06635,15.06635,0,0,0,8.89021,3.46944c5.3925.23446,4.94362,7.26815,1.41675,5.158S209.74816,707.07414,209.74816,707.07414Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M208.37518,711.58507s3.464,17.01166,6.27743,13.72927"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.1"
      />
      <path
        d="M125.22035,694.43414a20.81252,20.81252,0,1,0,2.7716-39.91524l.52093,10.7122-5.06815-9.18045a20.734,20.734,0,0,0-10.68366,11.72261,20.40847,20.40847,0,0,0-1.19713,5.62986A20.80855,20.80855,0,0,0,125.22035,694.43414Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#57b894"
      />
      <path
        d="M150.29769,818.67756c-1.78906-9.11027,5.96331-17.1868,13.62087-22.43651s16.605-10.40779,19.21775-19.31684c3.755-12.80387-7.43-24.52981-16.13564-34.64176a125.3,125.3,0,0,1-16.52359-24.55738c-1.81108-3.5325-3.47558-7.22528-3.95222-11.16626-.6864-5.67546,1.13694-11.32309,2.97391-16.73673q9.17923-27.05169,19.62842-53.65005"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M126.07747,691.6762a20.81252,20.81252,0,1,0,2.77159-39.91524l.52094,10.7122-5.06815-9.18045a20.734,20.734,0,0,0-10.68367,11.72261,20.40883,20.40883,0,0,0-1.19713,5.62986A20.80857,20.80857,0,0,0,126.07747,691.6762Z"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M147.75389,632.47266A20.78823,20.78823,0,0,1,162.73382,619.275l1.71362,10.18378,3.177-10.69566a20.81,20.81,0,1,1-19.87057,13.70952Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#57b894"
      />
      <path
        d="M148.89672,628.7954a20.7882,20.7882,0,0,1,14.97993-13.19763l1.71361,10.18378,3.177-10.69567a20.81,20.81,0,1,1-19.87057,13.70952Z"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M163.34728,742.48885a20.81,20.81,0,0,0,18.419-37.02267l-2.44122,8.21926-1.731-10.30382a.3621.3621,0,0,0-.053-.0201,20.81113,20.81113,0,1,0-14.1938,39.12733Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#57b894"
      />
      <path
        d="M164.33749,738.76417a20.81,20.81,0,0,0,18.419-37.02267l-2.44121,8.21926-1.73105-10.30382a.36183.36183,0,0,0-.053-.0201,20.81113,20.81113,0,1,0-14.1938,39.12733Z"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M141.68036,777.05148a20.80131,20.80131,0,1,0,4.01058-16.29737l9.27267,13.95654L142.29366,767.303A20.61637,20.61637,0,0,0,141.68036,777.05148Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#57b894"
      />
      <path
        d="M142.53747,774.29354a20.80132,20.80132,0,1,0,4.01059-16.29737l9.27266,13.95655L143.15078,764.545A20.61651,20.61651,0,0,0,142.53747,774.29354Z"
        transform="translate(-111.51074 -48.78194)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M121.40226,831.01764s18.36546-7.19731,23.90035-11.13863,28.2507-8.64747,29.62377-2.32646,27.59987,31.43784,6.86536,31.60509-48.17778,3.40194-53.702.03692S121.40226,831.01764,121.40226,831.01764Z"
        transform="translate(-111.51074 -48.78194)"
        fill="#656380"
      />
      <path
        d="M182.16177,846.95727c-20.73451.16729-48.17777,3.402-53.702.03693-4.207-2.56265-5.88345-11.75807-6.44438-16.00047-.38846.0167-.61311.02389-.61311.02389s1.16323,14.81188,6.68747,18.17692,32.9675.13036,53.702-.03693c5.98523-.04827,8.05264-2.17773,7.93912-5.33164C188.89938,845.73148,186.61666,846.92135,182.16177,846.95727Z"
        transform="translate(-111.51074 -48.78194)"
        opacity="0.2"
      />
      <line
        x1="50.81609"
        y1="799.55547"
        x2="322.81609"
        y2="799.55547"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Download;
