import { Module } from '../@types/user';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';

interface GetAllModuleResult {
  modules?: Module[];
  error?: string;
}

interface GetAllModuleResponse {
  modules?: Module[];
}

const getAllModule = async () => {
  const result: GetAllModuleResult = {};
  try {
    const response = await API.get<GetAllModuleResponse>('/modules');
    result.modules = response.data.modules;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const ModuleService = {
  getAllModule,
};

export default ModuleService;
