import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { BrandingActivity } from '../../../@types/branding';
import ActivityStatusTag from '../../../components/ActivityStatusTag';
import DisplayDate from '../../../components/DisplayDate';
import CanView from '../../../components/RoleBasedAccess/CanView';
import { ActivityType } from '../../../enums/branding';
import { ROLE } from '../../../enums/role';
import { formatDateTime } from '../../../util/datetime';

interface ActivityInfoProps {
  brandingActivity: BrandingActivity;
}
const ActivityInfo: React.FunctionComponent<ActivityInfoProps> = ({ brandingActivity }) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Activity Info</Typography>} />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">ID</TableCell>
            <TableCell className="capitalize">{brandingActivity.displayId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Date</TableCell>

            <TableCell className="capitalize cursor-pointer">
              {brandingActivity.activityDate && (
                <Tooltip title={formatDateTime(brandingActivity.activityDate)} placement="top">
                  <span>
                    <DisplayDate date={brandingActivity.activityDate} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
            <TableRow>
              <TableCell className="font-medium capitalize">Client</TableCell>
              <TableCell className="capitalize">{brandingActivity.client?.name}</TableCell>
            </TableRow>
          </CanView>
          <TableRow>
            <TableCell className="font-medium capitalize">Activity Type</TableCell>
            <TableCell className="capitalize">{brandingActivity.activityType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Branding Type</TableCell>
            <TableCell className="capitalize">{brandingActivity.brandingType?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Visibility Element</TableCell>
            <TableCell className="capitalize">{brandingActivity.visibilityElement?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Outlet Code</TableCell>
            <TableCell className="capitalize">{brandingActivity.outletCode}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Outlet Name</TableCell>
            <TableCell className="capitalize">{brandingActivity.outletName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Outlet Mobile</TableCell>
            <TableCell className="capitalize">{brandingActivity.outletMobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Distributer</TableCell>
            <TableCell className="capitalize">{brandingActivity.distributerName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Sales Person Name</TableCell>
            <TableCell className="capitalize">{brandingActivity.salesPersonName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Sales Person Mobile</TableCell>
            <TableCell className="capitalize">{brandingActivity.salesPersonMobile}</TableCell>
          </TableRow>
          {brandingActivity.activityType === ActivityType.RECEE && (
            <React.Fragment>
              <TableRow>
                <TableCell className="font-medium capitalize">Sizes in inches</TableCell>
                <TableCell className="capitalize">
                  <Typography variant="subtitle2" className="font-bold" component="span">
                    {brandingActivity.boardWidth} inches
                  </Typography>
                  <span className="font-bold m-2">x</span>
                  <Typography variant="subtitle2" className="font-bold" component="span">
                    {brandingActivity.boardHeight} inches
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium capitalize">Quantity</TableCell>
                <TableCell className="capitalize">{brandingActivity.quantity}</TableCell>
              </TableRow>
            </React.Fragment>
          )}
          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
            <TableRow>
              <TableCell className="font-medium capitalize">Status</TableCell>
              <TableCell className="capitalize">
                <ActivityStatusTag status={brandingActivity.status} />
              </TableCell>
            </TableRow>
          </CanView>
          <TableRow>
            <TableCell className="font-medium capitalize">Remark</TableCell>
            <TableCell className="capitalize">{brandingActivity.remark}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default ActivityInfo;
