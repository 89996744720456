import { Card, CardHeader, Typography, Divider, Grid, CardMedia, Box } from '@material-ui/core';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { BrandingActivity } from '../../../@types/branding';

interface AcknowledgementProps {
  brandingActivity: BrandingActivity;
}
const Acknowledgement: React.FC<AcknowledgementProps> = ({ brandingActivity }) => {
  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);

  const handleAcknowledgementOpen = (index: number) => {
    const acknowledgements = brandingActivity.acknowledgements ?? [];
    const images = acknowledgements?.map((acknowledgement) => acknowledgement.url);
    setImages(images);
    setPhotoIndex(index);
    setLightBoxOpen(true);
  };

  const handleCloseLightBox = () => {
    setImages([]);
    setPhotoIndex(0);
    setLightBoxOpen(false);
  };

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Acknowledgement document</Typography>} />
      <Divider />
      <Box padding={3}>
        <Grid container spacing={3}>
          {brandingActivity.acknowledgements?.map((acknowledgement, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={acknowledgement.url}>
              <Card onClick={() => handleAcknowledgementOpen(index)} className="w-40 cursor-pointer">
                <CardMedia
                  component="img"
                  image={acknowledgement.url}
                  alt="acknowledgement"
                  className="w-40 h-40 object-cover"
                />
              </Card>
            </Grid>
          ))}
        </Grid>
        {isLightBoxOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={handleCloseLightBox}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex) => (photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex) => (photoIndex + 1) % images.length)
            }
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
          />
        )}
      </Box>
    </Card>
  );
};

export default Acknowledgement;
