import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { BrandingType } from '../../@types/branding';
import BrandingTypeService from '../../services/branding-type.service';
import BrandingTypeFormDialog from './BrandingTypeFormDialog';

interface EditBrandingTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  brandingTypeId: string;
}

const EditBrandingTypeDialog: React.FC<EditBrandingTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
  brandingTypeId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [brandingType, setBrandingType] = useState<BrandingType | null>(null);

  const handleSave = useCallback(async (data: BrandingType) => {
    const { message, error } = await BrandingTypeService.updateBrandingType({
      ...data,
      id: brandingTypeId,
    });
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getBrandingTypeById = async () => {
      const { brandingType } = await BrandingTypeService.getBrandingTypeById(brandingTypeId);
      if (brandingType) {
        setBrandingType(brandingType);
      }
    };
    getBrandingTypeById();
    // eslint-disable-next-line
  }, [brandingTypeId]);

  return (
    <>
      {brandingType && (
        <BrandingTypeFormDialog
          mode="edit"
          brandingType={brandingType}
          open={open}
          onClose={onClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditBrandingTypeDialog;
