import { User } from '../@types/user';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';

interface GetProfileDetailResponse {
  user: User;
}

interface GetProfileDetailResult {
  user?: User;
  error?: string;
}

const getProfileDetail = async (): Promise<GetProfileDetailResult> => {
  const result: GetProfileDetailResult = {};
  try {
    const response = await API.get<GetProfileDetailResponse>('/me');
    result.user = response.data.user;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const LoggedUserService = {
  getProfileDetail,
};

export default LoggedUserService;
