import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { ClientUserForm } from '../../@types';
import ClientUserService from '../../services/client-user.service';
import ClientUserFormDialog from './ClientUserFormDialog';

interface CreateClientUserDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  clientId: string;
}

const CreateClientUserDialog: React.FC<CreateClientUserDialogProps> = ({
  open,
  onClose,
  onSuccess,
  clientId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [clientUser] = useState<ClientUserForm>({ mobile: '' });

  const handleSave = useCallback(async (data: ClientUserForm) => {
    const { message, error } = await ClientUserService.createClientUser({
      ...data,
      id: '',
      clientId,
    });
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ClientUserFormDialog
      mode="create"
      clientUser={clientUser}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateClientUserDialog;
