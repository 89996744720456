import {
  Button,
  Drawer,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsLink } from '../components/common/Breadcrumbs/Breadcrumbs';
import Empty from '../components/common/Empty';
import LoadingIndicator from '../components/common/LoadingIndicator';
import MainLayout from '../components/MainLayout';
import { ROLE } from '../enums/role';
import withAuth from '../hocs/withAuth';
import withRoles from '../hocs/withRoles';
import BrandingActivityService from '../services/branding-activity.service';
import { BrandingActivity } from '../@types/branding';
import { Link, useHistory } from 'react-router-dom';
import CanView from '../components/RoleBasedAccess/CanView';
import ActivityTableRow from '../containers/BrandingActivity/ActivityTableRow';
import { ActivityType, BrandingActivityStatus } from '../enums/branding';
import { BrandingActivityFilterOption, PartialBrandingActivityFilterOption } from '../@types';
import QueryFilterPanel from '../containers/BrandingActivity/QueryFilterPanel';
import Pagination from '../components/common/Pagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import { formatDateTime } from '../util/datetime';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import useBrandingActivityFilterOption from '../hooks/useBrandingActivityFilterOption';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Branding',
    href: '/branding/activities',
  },
  {
    label: 'Branding Activities',
  },
];

const defaultFilterOption: BrandingActivityFilterOption = {
  query: '',
  page: 1,
  size: 20,
  filterType: 'filter',
  status: BrandingActivityStatus.ALL,
  state: '',
  town: '',
  brandingTypes: [],
  visibilityElements: [],
  startDate: null,
  endDate: null,
  clients: [],
  activityType: ActivityType.ALL,
};

const BrandingActivities = () => {
  const history = useHistory();
  const filterOption = useBrandingActivityFilterOption(defaultFilterOption);
  const { enqueueSnackbar } = useSnackbar();

  const [brandingActivities, setBrandingActivities] = useState<BrandingActivity[]>([]);
  const [loading, setLoading] = useState(false);

  const rowsPerPageOptions = [10, 20, 30, 40, 50];
  const [brandingActivityCount, setBrandingActivityCount] = useState(0);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const handleChangePage = (event: React.ChangeEvent<unknown> | undefined, newPage: number) => {
    history.push(`/branding/activities?page=${newPage}&size=${filterOption.size}`, {
      ...filterOption,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const size = parseInt(event.target.value as string, 10);
    history.push(`/branding/activities?page=1&size=${size}`, {
      ...filterOption,
      page: 1,
      size,
    });
  };

  const getAllBrandingActivity = async () => {
    setLoading(true);
    const {
      brandingActivities = [],
      count = 0,
      error,
    } = await BrandingActivityService.getAllBrandingActivity(filterOption);
    setBrandingActivityCount(count);
    setLoading(false);
    if (error) {
    } else {
      setBrandingActivities(brandingActivities);
    }
  };

  const handleSearch = (data: PartialBrandingActivityFilterOption) => {
    history.push(`/branding/activities?page=1&size=${filterOption.size}`, {
      ...filterOption,
      ...data,
      page: 1,
    });
    setFilterDrawerOpen(false);
  };

  const handleClear = (data: PartialBrandingActivityFilterOption) => {
    setFilterDrawerOpen(false);
    history.push(`/branding/activities?page=1&size=${filterOption.size}`, {
      ...filterOption,
      ...data,
      page: 1,
    });
  };

  const downloadExcel = async () => {
    const { data, error } = await BrandingActivityService.downloadActivitySheet(filterOption);
    if (data) {
      const blob = new Blob([data]);
      FileSaver.saveAs(blob, `Activity Data -${formatDateTime(new Date())}.xlsx`);
    } else if (error) {
      enqueueSnackbar(error);
    }
  };

  useEffect(() => {
    getAllBrandingActivity();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
                All Branding Activity
              </Typography>
            </Grid>
            <Grid item>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component={Link}
                  to="/branding/activities/new"
                  size="small"
                >
                  New Branding Activity
                </Button>
              </CanView>
            </Grid>
          </Grid>
          <Paper className="mt-5" elevation={1}>
            <Grid container spacing={3} justify="space-between" className="px-4">
              <Grid item>
                <Button
                  onClick={() => setFilterDrawerOpen(true)}
                  color="primary"
                  variant="outlined"
                  className="mr-2"
                >
                  <FilterListIcon className="mr-2" />
                  Show Filter
                </Button>
                <Button
                  onClick={() => handleClear(defaultFilterOption)}
                  color="primary"
                  variant="outlined"
                >
                  <ClearIcon className="mr-2" />
                  Clear Filter
                </Button>
              </Grid>
              {brandingActivityCount > 0 && (
                <Grid item>
                  <Button
                    disableElevation
                    className="ml-2"
                    onClick={downloadExcel}
                    size="small"
                    color="primary"
                    variant="outlined"
                  >
                    <GetAppIcon className="mr-2" />
                    Download Excel
                  </Button>
                </Grid>
              )}
            </Grid>
            <Drawer
              open={filterDrawerOpen}
              anchor="right"
              onClose={() => setFilterDrawerOpen(false)}
              PaperProps={{ style: { width: '400px' } }}
            >
              {filterDrawerOpen && (
                <QueryFilterPanel
                  onSearch={handleSearch}
                  onClear={handleClear}
                  filterOption={filterOption}
                  count={brandingActivityCount}
                />
              )}
            </Drawer>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={brandingActivities.length === 0} imgClassName="w-4/12">
                <TableContainer className="mt-5" style={{ maxHeight: '700px' }}>
                  <Table stickyHeader aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className="w-8"></TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Outlet</TableCell>
                        <TableCell> Town {'&'} State </TableCell>
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
                          <TableCell> Client </TableCell>
                        </CanView>
                        <TableCell>Activity Type</TableCell>
                        <TableCell>Branding Type</TableCell>
                        <TableCell>Visibility Element</TableCell>
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
                          <TableCell>Promoter</TableCell>
                          <TableCell>Status</TableCell>
                        </CanView>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {brandingActivities.map((brandingActivity, index) => (
                        <ActivityTableRow
                          brandingActivity={brandingActivity}
                          key={brandingActivity.id}
                          index={index}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Empty>
            </LoadingIndicator>
            {brandingActivityCount > 0 && (
              <Pagination
                count={brandingActivityCount}
                rowsPerPage={filterOption.size}
                page={filterOption.page}
                onChange={handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(BrandingActivities, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]),
  '/login',
);
