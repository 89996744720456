import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import cx from 'classnames';
import { IMenusList } from '../../@types/sidebar';
import { User } from '../../@types/user';
import { hasRole } from '../../util/role';
import { ROLE } from '../../enums/role';

interface SideBarMenuListProps {
  title?: string;
  menuList: IMenusList[];
  isActive: (menu: IMenusList) => boolean;
  isVisible: (menu: IMenusList) => boolean;
  user: User;
}

const SideBarMenuListWrapper = styled(List)`
  .menu-item.active {
    color: var(--color);

    .menu-item-icon {
      color: var(--color);
    }
  }
  .menu-item-icon {
    min-width: 40px;
  }
`;
const SideBarMenuList: React.FunctionComponent<SideBarMenuListProps> = ({
  menuList,
  isActive,
  isVisible,
  title,
  user,
}) => {
  const filteredMenuList = menuList.filter(
    (menu) => user && hasRole(user.roles, menu.roles as ROLE[]),
  );
  return (
    <SideBarMenuListWrapper
      subheader={title ? <ListSubheader className="font-bold">{title}</ListSubheader> : <></>}
    >
      {filteredMenuList
        .filter((menu) => isVisible(menu))
        .map((menu) => {
          const { title, icon: Icon, path } = menu;
          const active = isActive(menu);
          return (
            <ListItem
              selected={active}
              button
              component={Link}
              to={path}
              key={path}
              className={cx('menu-item', { active })}
            >
              <ListItemIcon className="menu-item-icon">
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} primaryTypographyProps={{ className: 'capitalize' }} />
            </ListItem>
          );
        })}
    </SideBarMenuListWrapper>
  );
};

export default SideBarMenuList;
