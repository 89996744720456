import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { BrandingType } from '../../@types/branding';
import DialogTitle from '../../components/common/Dialog/DialogTitle';
import { brandingTypeFormSchema } from '../../validation/branding-type';

interface BrandingTypeFormProps {
  mode: 'create' | 'edit';
  open: boolean;
  brandingType: BrandingType;
  onSave?: (brandingType: BrandingType) => void;
  onClose?: () => void;
}

const BrandingTypeFormDialog: React.FC<BrandingTypeFormProps> = ({
  mode,
  brandingType,
  onSave,
  open,
  onClose,
}) => {
  const { register, errors, handleSubmit } = useForm<BrandingType>({
    defaultValues: brandingType,
    mode: 'onSubmit',
    resolver: joiResolver(brandingTypeFormSchema),
  });

  const onSubmit = (data: BrandingType) => {
    onSave?.(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          title={mode === 'create' ? 'Create branding type' : 'Update branding type'}
          onClose={onClose}
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Name"
                className="w-full"
                id="name"
                name="name"
                error={!!errors.name}
                helperText={errors.name?.message}
                inputRef={register}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {mode === 'create' ? 'Create' : 'Save '}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default BrandingTypeFormDialog;
