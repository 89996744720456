import React from 'react';
import cx from 'classnames';

interface LayoutProps {
  className?: string;
}
const Layout: React.FC<LayoutProps> = ({ children, className }) => {
  return <div className={cx('w-full h-full flex', className)}>{children}</div>;
};

export default Layout;
