import { Avatar, Chip, Typography } from '@material-ui/core';
import React from 'react';
import { User } from '../../@types/user';

interface SidebarProfileProps {
  user: User;
}

const SidebarProfile: React.FunctionComponent<SidebarProfileProps> = ({ user }) => {
  return (
    <div className="flex flex-col items-center py-5">
      <Avatar alt={user?.name} className="profile-avatar mb-2" />
      <Typography color="textPrimary" className="font-bold mb-2">
        {user?.name}
      </Typography>

      <div>
        {user?.roles.map((role) => (
          <Chip className="mr-2" label={role.name} size="small" key={role.key} />
        ))}
      </div>
    </div>
  );
};

export default SidebarProfile;
