import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { BrandingType, VisibilityElement } from '../../@types/branding';
import BrandingTypeService from '../../services/branding-type.service';
import VisibilityElementService from '../../services/visibility-element.service';
import CanView from '../../components/RoleBasedAccess/CanView';
import { ROLE } from '../../enums/role';
import { ActivityType, BrandingActivityStatus } from '../../enums/branding';
import { Controller, useForm } from 'react-hook-form';
import DatePickerController from '../../components/common/DatePickerController';
import AutocompleteController from '../../components/common/AutocompleteController';
import {
  PartialBrandingActivityFilterOption,
  BrandingActivityFilterOption,
  Client,
} from '../../@types';
import { isDateBefore } from '../../util/datetime';
import ClientService from '../../services/client.service';
import debounce from 'lodash.debounce';
import { hasAnyRoleTillManager } from '../../util/role';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { AuthState } from '../../store/auth/types';

interface QueryFilterPanelProps {
  onSearch?: (data: PartialBrandingActivityFilterOption) => void;
  onClear?: (data: PartialBrandingActivityFilterOption) => void;
  filterOption: BrandingActivityFilterOption;
  count: number;
}

const defaultQuertFilterValues: PartialBrandingActivityFilterOption = {
  query: '',
  status: BrandingActivityStatus.ALL,
  state: '',
  town: '',
  brandingTypes: [],
  visibilityElements: [],
  startDate: null,
  endDate: null,
  clients: [],
  activityType: ActivityType.ALL,
};

const QueryFilterPanel: React.FC<QueryFilterPanelProps> = ({ onSearch, filterOption }) => {
  const { user } = useSelector<RootState, AuthState>((state) => state.authState);
  const [brandingTypes, setBrandingTypes] = useState<BrandingType[]>([]);
  const [visibilityElements, setVisibilityElements] = useState<VisibilityElement[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
  } = useForm<PartialBrandingActivityFilterOption>({
    mode: 'all',
    defaultValues: filterOption,
  });

  const getAllBrandingType = async () => {
    const { brandingTypes } = await BrandingTypeService.getAllBrandingType({ filterType: 'all' });
    if (brandingTypes) {
      setBrandingTypes(brandingTypes);
    }
  };

  const getAllVisibilityElement = async () => {
    const { visibilityElements } = await VisibilityElementService.getAllVisibilityElement({
      filterType: 'all',
    });
    if (visibilityElements) {
      setVisibilityElements(visibilityElements);
    }
  };

  const getAllClient = async (query: string = '') => {
    const { clients } = await ClientService.getAllClient({
      filterType: 'filter',
      query,
      page: 1,
      size: 25,
    });
    if (clients) {
      setClients(clients);
    }
  };

  useEffect(() => {
    getAllBrandingType();
    getAllVisibilityElement();
    if (user && hasAnyRoleTillManager(user?.roles)) {
      getAllClient();
    }
  }, []);

  const handleSearchClick = (data: PartialBrandingActivityFilterOption) => {
    onSearch?.(data);
  };

  const handleClearClick = () => {
    reset(defaultQuertFilterValues);
    onSearch?.(defaultQuertFilterValues);
  };

  const startDate = watch('startDate');

  const debounceClientSearch = debounce((value: string) => {
    getAllClient(value);
  }, 300);

  const handleClientInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    debounceClientSearch(value);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleSearchClick)}>
      <Typography variant="h6" className="pt-4 px-4">
        Filter Branding Activity
      </Typography>
      <Grid container spacing={3} className="pt-2 px-5 mb-2">
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant="outlined"
            placeholder="Search activity"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            className="w-full"
            name="query"
            inputRef={register}
            size="small"
          />
        </Grid>
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl variant="outlined" className="w-full" size="small">
              <InputLabel id="status-label">Status</InputLabel>
              <Controller
                name="status"
                control={control}
                as={
                  <Select labelId="status-label" label="Status">
                    <MenuItem value={BrandingActivityStatus.ALL}>All</MenuItem>
                    <MenuItem value={BrandingActivityStatus.PENDING}>Pending</MenuItem>
                    <MenuItem value={BrandingActivityStatus.APPROVED}>Approved</MenuItem>
                  </Select>
                }
              />
            </FormControl>
          </Grid>
        </CanView>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl variant="outlined" className="w-full" size="small">
            <InputLabel id="activity-type-label">Activity Type</InputLabel>
            <Controller
              name="activityType"
              control={control}
              as={
                <Select labelId="activity-type-label" label="Activity Type">
                  <MenuItem value={ActivityType.ALL}>All</MenuItem>
                  <MenuItem value={ActivityType.RECEE}>Recee</MenuItem>
                  <MenuItem value={ActivityType.INSTALLATION}>Installation</MenuItem>
                  <MenuItem value={ActivityType.RECTIFICATION}>Rectification</MenuItem>
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DatePickerController
            label="Start date"
            className="w-full m-0"
            control={control}
            id="startDate"
            name="startDate"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DatePickerController
            label="End date"
            className="w-full m-0"
            control={control}
            id="endDate"
            name="endDate"
            shouldDisableDate={(day) => !!startDate && isDateBefore(day as Date, startDate)}
            disabled={!startDate}
            size="small"
          />
        </Grid>
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
          <Grid item xs={12} sm={12} md={12}>
            <AutocompleteController
              options={clients}
              getOptionLabel={(client) => client.name}
              getOptionSelected={(option, value) => option.id === value.id}
              label="Clients"
              placeholder="Clients"
              className="w-full"
              control={control}
              name="clients"
              id="clients"
              onInputChange={handleClientInputChange}
              size="small"
              multiple
            />
          </Grid>
        </CanView>
        <Grid item xs={12} sm={12} md={12}>
          <AutocompleteController
            options={brandingTypes}
            getOptionLabel={(brandingType) => brandingType.name}
            getOptionSelected={(option, value) => option.id === value.id}
            label="Branding Types"
            placeholder="Branding Types"
            className="w-full"
            control={control}
            name="brandingTypes"
            id="brandingTypes"
            size="small"
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AutocompleteController
            options={visibilityElements}
            getOptionLabel={(visibilityElement) => visibilityElement.name}
            getOptionSelected={(option, value) => option.id === value.id}
            label="Visibility Element"
            placeholder="Visibility Element"
            className="w-full"
            control={control}
            name="visibilityElements"
            id="visibilityElements"
            size="small"
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant="outlined"
            placeholder="State"
            className="w-full"
            name="state"
            inputRef={register}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant="outlined"
            placeholder="Town"
            className="w-full"
            name="town"
            inputRef={register}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container justify="space-between" spacing={3} className="px-5">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className="mr-4"
            type="submit"
          >
            Search
          </Button>
          <Button variant="contained" disableElevation onClick={handleClearClick}>
            Clear
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QueryFilterPanel;
