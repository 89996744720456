import { Box, Typography, Grid, Backdrop } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BrandingActivity } from '../../@types/branding';
import Breadcrumbs, { BreadcrumbsLink } from '../../components/common/Breadcrumbs/Breadcrumbs';
import CircularProgressWithLabel from '../../components/common/Circular';
import MainLayout from '../../components/MainLayout';
import BrandingActivityForm from '../../containers/BrandingActivity/BrandingActivityForm';
import { ActivityType, BrandingActivityStatus } from '../../enums/branding';
import { ROLE } from '../../enums/role';
import withAuth from '../../hocs/withAuth';
import withRoles from '../../hocs/withRoles';
import BrandingActivityService from '../../services/branding-activity.service';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Branding',
    href: '/branding/activities',
  },
  {
    label: 'Branding Activities',
    href: '/branding/activities',
  },
];

const CreateBrandingActivity = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [brandingActivity] = useState<BrandingActivity>({
    id: '',
    userId: '',
    state: '',
    town: '',
    district: '',
    distributerName: '',
    salesPersonName: '',
    salesPersonMobile: '',
    outletName: '',
    outletMobile: '',
    quantity: 0,
    boardWidth: 0,
    boardHeight: 0,
    status: BrandingActivityStatus.PENDING,
    lat: 19.013748,
    lng: 72.841928,
    remark: '',
    brandingTypeId: '',
    visibilityElementId: '',
    attachments: [],
    acknowledgements: [],
    activityDate: new Date(),
    clientId: '',
    activityType: ActivityType.RECEE,
    outletCode: '',
  });

  const [progress, setProgress] = useState(0);
  const [saving, setSaving] = useState(false);

  const handleSave = async (data: BrandingActivity) => {
    setSaving(true);
    const { brandingActivity, error } = await BrandingActivityService.createBrandingActivity(
      {
        ...data,
      },
      {
        onUploadProgress: (percent) => {
          setProgress(percent);
        },
      },
    );
    setSaving(false);
    setProgress(0);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (brandingActivity) {
      history.push(`/branding/activities/${brandingActivity.id}`);
    }
  };

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Breadcrumbs links={links} />
          <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
            Create Branding Activity
          </Typography>
          <Box marginTop={2}>
            <BrandingActivityForm
              mode="create"
              brandingActivity={brandingActivity}
              onSave={handleSave}
            />
          </Box>
        </Grid>
      </Grid>
      <Backdrop open={saving} style={{ zIndex: 2000 }} className="flex-col">
        <CircularProgressWithLabel value={progress} />
        <Typography variant="h6" className="text-white">
          uploading
        </Typography>
      </Backdrop>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(CreateBrandingActivity, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  '/login',
);
