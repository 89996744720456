import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import {
  BrandingType,
  VisibilityElement,
  BrandingActivity,
  BrandingActivityAttachment,
  BrandingActivityAcknowledgement,
} from '../../../@types/branding';
import BrandingTypeService from '../../../services/branding-type.service';
import VisibilityElementService from '../../../services/visibility-element.service';
import { Map, Marker, TileLayer } from 'react-leaflet';
import FileUploadButton from '../../../components/common/FileUploadButton';
import { readFile } from '../../../helpers/file';
import Lightbox from 'react-image-lightbox';
import { joiResolver } from '@hookform/resolvers/joi';
import BrandingActivityService from '../../../services/branding-activity.service';
import { useSnackbar } from 'notistack';
import DatePickerController from '../../../components/common/DatePickerController';
import FileSize from '../../../components/FileSize';
import AutocompleteController from '../../../components/common/AutocompleteController';
import { Client } from '../../../@types';
import ClientService from '../../../services/client.service';
import debounce from 'lodash.debounce';
import { ActivityType } from '../../../enums/branding';
import { getBrandingActivitySchema } from '../../../util/branding-activity';

interface BrandingActivityFormProps {
  mode: 'create' | 'edit';
  brandingActivity: BrandingActivity;
  onSave?: (brandingActivity: BrandingActivity) => void;
}

const BrandingActivityForm: React.FC<BrandingActivityFormProps> = ({
  mode,
  brandingActivity,
  onSave,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { register, errors, handleSubmit, control, watch, setValue } = useForm<BrandingActivity>({
    defaultValues: brandingActivity,
    mode: 'onSubmit',
    resolver: async (data) => {
      const schema = getBrandingActivitySchema(data.activityType);
      const resolver = joiResolver(schema);
      const { values, errors }: { values: any; errors: any } = await resolver(data);
      return { values, errors };
    },
  });

  const selectBrandingTypeId = watch('brandingTypeId');
  const activityType = watch('activityType');

  const [latlng, setLatlng] = useState({ lat: brandingActivity.lat, lng: brandingActivity.lng });

  const [brandingTypes, setBrandingTypes] = useState<BrandingType[]>([]);

  const [visibilityElements, setVisibilityElements] = useState<VisibilityElement[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  const [attachments, setAttachments] = useState<BrandingActivityAttachment[]>(
    brandingActivity.attachments ?? [],
  );
  const [acknowledgements, setAcknowledgements] = useState<BrandingActivityAcknowledgement[]>(
    brandingActivity.acknowledgements ?? [],
  );

  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);

  const [filesError, setFilesError] = useState({ attachments: '', acknowledgements: '' });

  const activityTypes: ActivityType[] = [
    ActivityType.RECEE,
    ActivityType.INSTALLATION,
    ActivityType.RECTIFICATION,
  ];

  const validateAttachments = () => {
    if (attachments.length === 0) {
      return 'Pictures is empty';
    } else if (attachments.length < 2) {
      return 'Please select atleast 2 pictures';
    } else {
      return '';
    }
  };

  const validateAcknowledgements = () => {
    if (acknowledgements.length === 0) {
      return 'Acknowledgement is empty';
    } else if (acknowledgements.length < 1) {
      return 'Please select atleast 1 acknowledgement';
    } else {
      return '';
    }
  };

  const validateFilesError = () => {
    filesError.attachments = validateAttachments();

    if (activityType !== ActivityType.RECTIFICATION) {
      filesError.acknowledgements = validateAcknowledgements();
    } else {
      filesError.acknowledgements = '';
    }

    setFilesError({ ...filesError });

    return !Object.values(filesError).some((error) => error !== '');
  };

  const onSubmit = (data: BrandingActivity) => {
    if (validateFilesError()) {
      onSave?.({ ...data, ...latlng, attachments, acknowledgements });
    }
  };

  const getAllBrandingType = async () => {
    const { brandingTypes } = await BrandingTypeService.getAllBrandingType({ filterType: 'all' });
    if (brandingTypes) {
      setBrandingTypes(brandingTypes);
    }
  };

  const getAllVisibilityElement = async () => {
    const { visibilityElements } = await VisibilityElementService.getAllVisibilityElement({
      filterType: 'all',
    });
    if (visibilityElements) {
      setVisibilityElements(visibilityElements);
    }
  };

  const getAllClient = async (query: string = '') => {
    const { clients } = await ClientService.getAllClient({
      filterType: 'all',
      query,
      page: 1,
      size: 25,
    });
    if (clients) {
      setClients(clients);
    }
  };

  useEffect(() => {
    getAllBrandingType();
    getAllVisibilityElement();
    getAllClient();
  }, []);

  const handleMapClick = (data: any) => {
    const { lat, lng } = data.latlng;
    setLatlng({ lat, lng });
  };

  const handlePhotoUploadChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (file) {
        if (file.size > 1024 * 1024) {
          enqueueSnackbar('File too large', { variant: 'error' });
        } else {
          const result = await readFile(file);
          setAttachments((attachments) => {
            const attachment: BrandingActivityAttachment = {
              id: '',
              name: file.name,
              contentLength: file.size,
              contentType: file.type,
              url: result,
              file,
            };
            const newAttachments = [...attachments, attachment];
            setValue('attachments', newAttachments);
            return newAttachments;
          });
        }
      }
    } catch (error) {}
  };

  const handleAckUploadChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (file) {
        if (file.size > 1024 * 1024) {
          enqueueSnackbar('File too large', { variant: 'error' });
        } else {
          const result = await readFile(file);
          setAcknowledgements((acknowledgements) => {
            const acknowledgement: BrandingActivityAcknowledgement = {
              id: '',
              name: file.name,
              contentLength: file.size,
              contentType: file.type,
              url: result,
              file,
            };
            const newAcknowledgements = [...acknowledgements, acknowledgement];
            setValue('attachments', newAcknowledgements);
            return newAcknowledgements;
          });
        }
      }
    } catch (error) {}
  };

  const handleAttachementOpen = (index: number) => {
    const images = attachments.map((attachment) => attachment.url);
    setImages(images);
    setPhotoIndex(index);
    setLightBoxOpen(true);
  };
  const handleAcknowledgementOpen = (index: number) => {
    const images = acknowledgements.map((acknowledgement) => acknowledgement.url);
    setImages(images);
    setPhotoIndex(index);
    setLightBoxOpen(true);
  };

  const handleCloseLightBox = () => {
    setImages([]);
    setPhotoIndex(0);
    setLightBoxOpen(false);
  };

  const deleteAttachment =
    (index: number) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      const attachment = attachments[index];
      if (attachment.id) {
        const { message, error } = await BrandingActivityService.deleteAttachment(
          brandingActivity.id,
          attachment.id,
        );
        if (message) {
          attachments.splice(index, 1);
          enqueueSnackbar(message, { variant: 'success' });
        } else if (error) {
          enqueueSnackbar(error, { variant: 'error' });
        }
      } else {
        attachments.splice(index, 1);
      }
      setAttachments([...attachments]);
    };

  const deleteAcknowledgement =
    (index: number) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      const acknowledgement = acknowledgements[index];
      if (acknowledgement.id) {
        const { message, error } = await BrandingActivityService.deleteAcknowledgement(
          brandingActivity.id,
          acknowledgement.id,
        );
        if (message) {
          acknowledgements.splice(index, 1);
          enqueueSnackbar(message, { variant: 'success' });
        } else if (error) {
          enqueueSnackbar(error, { variant: 'error' });
        }
      } else {
        acknowledgements.splice(index, 1);
      }
      setAcknowledgements([...acknowledgements]);
    };

  const debounceClientSearch = debounce((value: string) => {
    getAllClient(value);
  }, 300);

  const handleClientInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    debounceClientSearch(value);
  };

  console.log(errors);

  return (
    <Card>
      <CardContent>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, () => {
            validateFilesError();
          })}
        >
          <Box marginBottom={2}>
            <Typography variant="h6">Basic details</Typography>
          </Box>
          <Box marginBottom={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <DatePickerController
                  label="Activity date"
                  className="w-full m-0"
                  error={!!errors?.activityDate}
                  helperText={errors?.activityDate?.message}
                  control={control}
                  id="activityDate"
                  name="activityDate"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl variant="outlined" className="w-full" error={!!errors?.activityType}>
                  <InputLabel id="activity-type-label">Activity Type</InputLabel>
                  <Controller
                    name="activityType"
                    control={control}
                    as={
                      <Select labelId="activity-type-label" label="Activity Type">
                        {activityTypes.map((activityType) => (
                          <MenuItem value={activityType} key={activityType}>
                            {activityType.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                  <FormHelperText>{errors?.activityType?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <AutocompleteController
                  options={clients}
                  getOptionLabel={(client) => client.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  label="Brand"
                  placeholder="Brand"
                  className="w-full"
                  control={control}
                  name="client"
                  id="client"
                  onInputChange={handleClientInputChange}
                  error={!!errors.client}
                  helperText={(errors?.client as FieldError | undefined)?.message}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="outlined" className="w-full" error={!!errors?.brandingTypeId}>
                  <InputLabel id="branding-type-label">Branding Type</InputLabel>
                  <Controller
                    name="brandingTypeId"
                    control={control}
                    as={
                      <Select labelId="branding-type-label" label="Branding Type">
                        {brandingTypes.map((brandingType) => (
                          <MenuItem value={brandingType.id} key={brandingType.id}>
                            {brandingType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                  <FormHelperText>{errors?.brandingTypeId?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl
                  variant="outlined"
                  className="w-full"
                  error={!!errors?.visibilityElementId}
                >
                  <InputLabel id="visibility-element-label">Visibility Element</InputLabel>
                  <Controller
                    name="visibilityElementId"
                    control={control}
                    as={
                      <Select labelId="visibility-element-label" label="Visibility Element">
                        {visibilityElements
                          .filter(
                            (e) =>
                              !!selectBrandingTypeId && e.brandingTypeId === selectBrandingTypeId,
                          )
                          .map((visibilityElement) => (
                            <MenuItem value={visibilityElement.id} key={visibilityElement.id}>
                              {visibilityElement.name}
                            </MenuItem>
                          ))}
                      </Select>
                    }
                  />
                  <FormHelperText>{errors?.visibilityElementId?.message}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box marginBottom={4}>
                  <TextField
                    variant="outlined"
                    label="Town (optional)"
                    className="w-full"
                    id="town"
                    name="town"
                    error={!!errors.town}
                    helperText={errors.town?.message}
                    inputRef={register}
                  />
                </Box>
                <Box marginBottom={4}>
                  <TextField
                    variant="outlined"
                    label="District (optional)"
                    className="w-full"
                    id="district"
                    name="district"
                    error={!!errors.district}
                    helperText={errors.district?.message}
                    inputRef={register}
                  />
                </Box>
                <Box marginBottom={4}>
                  <TextField
                    variant="outlined"
                    label="State (optional)"
                    className="w-full"
                    id="state"
                    name="state"
                    error={!!errors.state}
                    helperText={errors.state?.message}
                    inputRef={register}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {latlng.lat !== undefined && latlng.lng !== undefined && (
                  <Map
                    center={{ lat: latlng.lat, lng: latlng.lng }}
                    zoom={11}
                    onclick={handleMapClick}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={{ lat: latlng.lat, lng: latlng.lng }}></Marker>
                  </Map>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="Outlet Code"
                  className="w-full"
                  id="outletCode"
                  name="outletCode"
                  error={!!errors.outletCode}
                  helperText={errors.outletCode?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="Outlet name"
                  className="w-full"
                  id="outletName"
                  name="outletName"
                  error={!!errors.outletName}
                  helperText={errors.outletName?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="Outlet mobile"
                  className="w-full"
                  id="outletMobile"
                  name="outletMobile"
                  error={!!errors.outletMobile}
                  helperText={errors.outletMobile?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="Distributer Name (optional)"
                  className="w-full"
                  id="distributerName"
                  name="distributerName"
                  error={!!errors.distributerName}
                  helperText={errors.distributerName?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="Sales person name (optional)"
                  className="w-full"
                  id="salesPersonName"
                  name="salesPersonName"
                  error={!!errors.salesPersonName}
                  helperText={errors.salesPersonName?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="Sales person mobile (optional)"
                  className="w-full"
                  id="salesPersonMobile"
                  name="salesPersonMobile"
                  error={!!errors.salesPersonMobile}
                  helperText={errors.salesPersonMobile?.message}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          {activityType === ActivityType.RECEE && (
            <React.Fragment>
              <Box marginBottom={2} marginTop={2}>
                <Typography variant="h6">Sizes in Inches</Typography>
              </Box>
              <Box marginBottom={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      variant="outlined"
                      label="Width in Inches"
                      className="w-full"
                      id="boardWidth"
                      name="boardWidth"
                      error={!!errors.boardWidth}
                      helperText={errors.boardWidth?.message}
                      inputRef={register}
                      type="number"
                      InputProps={{
                        inputProps: {
                          step: '0.01',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      variant="outlined"
                      label="Height in Inches"
                      className="w-full"
                      id="boardHeight"
                      name="boardHeight"
                      error={!!errors.boardHeight}
                      helperText={errors.boardHeight?.message}
                      inputRef={register}
                      type="number"
                      InputProps={{
                        inputProps: {
                          step: '0.01',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      variant="outlined"
                      label="Qty"
                      className="w-full"
                      id="quantity"
                      name="quantity"
                      error={!!errors.quantity}
                      helperText={errors.quantity?.message}
                      inputRef={register}
                      type="number"
                      InputProps={{
                        inputProps: {
                          step: '1',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          <Box marginBottom={2} marginTop={2}>
            <Typography variant="h6">
              Pictures upload{' '}
              <Typography variant="caption" className="text-red-500">
                (max 1MB)
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={3}>
              {attachments.map((attachment, index) => (
                <Grid item key={attachment.url}>
                  <Card
                    onClick={() => handleAttachementOpen(index)}
                    className="w-40 cursor-pointer"
                  >
                    <CardMedia
                      component="img"
                      image={attachment.url}
                      alt="activity"
                      className="w-40 h-40 object-cover"
                    />
                    <CardActions className="justify-between">
                      <Button size="small" color="primary" onClick={deleteAttachment(index)}>
                        Delete
                      </Button>
                      <FileSize size={attachment.contentLength} />
                    </CardActions>
                  </Card>
                </Grid>
              ))}

              {attachments.length < 2 && (
                <Grid item xs={12}>
                  <FileUploadButton
                    variant="contained"
                    disableElevation
                    accept="image/png,image/jpeg"
                    onChange={handlePhotoUploadChange}
                    name="attachments"
                    id="attachments"
                  >
                    Browse file
                  </FileUploadButton>
                  {filesError?.attachments && (
                    <FormHelperText error={!!filesError?.attachments}>
                      {filesError?.attachments}
                    </FormHelperText>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
          <Divider />
          {activityType !== ActivityType.RECTIFICATION && (
            <React.Fragment>
              <Box marginBottom={2} marginTop={2}>
                <Typography variant="h6">
                  Acknowledgement upload{' '}
                  <Typography variant="caption" className="text-red-500">
                    (max 1MB)
                  </Typography>
                </Typography>
              </Box>
              <Box marginBottom={4}>
                <Grid container spacing={3}>
                  {acknowledgements.map((acknowledgement, index) => (
                    <Grid item key={acknowledgement.url}>
                      <Card
                        onClick={() => handleAcknowledgementOpen(index)}
                        className="w-40 cursor-pointer"
                      >
                        <CardMedia
                          component="img"
                          image={acknowledgement.url}
                          alt="acknowledgement"
                          className="w-40 h-40 object-cover"
                        />
                        <CardActions className="justify-between">
                          <Button
                            size="small"
                            color="primary"
                            onClick={deleteAcknowledgement(index)}
                          >
                            Delete
                          </Button>
                          <FileSize size={acknowledgement.contentLength} />
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}

                  {acknowledgements.length < 2 && (
                    <Grid item xs={12}>
                      <FileUploadButton
                        variant="contained"
                        disableElevation
                        accept="image/png,image/jpeg"
                        onChange={handleAckUploadChange}
                        name="acknowledgements"
                        id="acknowledgements"
                      >
                        Browse file
                      </FileUploadButton>
                      {filesError?.acknowledgements && (
                        <FormHelperText error={!!filesError?.acknowledgements}>
                          {filesError?.acknowledgements}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          <Box marginBottom={2} marginTop={2}>
            <Typography variant="h6">Others</Typography>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Remark (optional)"
                  className="w-full"
                  id="remark"
                  name="remark"
                  error={!!errors.remark}
                  helperText={errors.remark?.message}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className="mb-5"
              type="submit"
              // disabled={!isValid}
            >
              {mode === 'create' ? 'Create' : 'Save'}
            </Button>
          </Grid>
        </Box>
        {isLightBoxOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={handleCloseLightBox}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex) => (photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex) => (photoIndex + 1) % images.length)
            }
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default BrandingActivityForm;
