import { BrandingActivity, BrandingType, VisibilityElement } from '../@types/branding';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';
import { BrandingActivityFilterOption, Client } from '../@types';
import parseISO from 'date-fns/parseISO';

const transformBrandingActivity = (data: any): BrandingActivity => {
  data.createdTime = parseISO(data.createdTime);
  data.activityDate = parseISO(data.activityDate);
  return data;
};

type GeAllBrandingActivityOptions = BrandingActivityFilterOption;

const parseGetAllBrandingActivityOptions = (options: GeAllBrandingActivityOptions) => {
  const updatedOptions = { ...options };
  updatedOptions.brandingTypes = (options.brandingTypes as BrandingType[]).map(
    (brandingType) => brandingType.name,
  );
  updatedOptions.visibilityElements = (options.visibilityElements as VisibilityElement[]).map(
    (visibilityElement) => visibilityElement.name,
  );
  updatedOptions.clients = (options.clients as Client[]).map(
    (client) => client.name,
  );
  return updatedOptions;
};

interface GetAllBrandingActivityResult {
  brandingActivities?: BrandingActivity[];
  count?: number;
  error?: string;
}

interface GetAllBrandingActivityResponse {
  brandingActivities: BrandingActivity[];
  count: number;
}

const getAllBrandingActivity = async (options: GeAllBrandingActivityOptions) => {
  const result: GetAllBrandingActivityResult = {};
  try {
    const {
      data: { brandingActivities, count },
    } = await API.post<GetAllBrandingActivityResponse>(
      `/branding-activities/search`,
      parseGetAllBrandingActivityOptions(options),
      {
        transformResponse: (data) => {
          data = JSON.parse(data);
          data.brandingActivities = data.brandingActivities.map(transformBrandingActivity);
          return data;
        },
      },
    );
    result.brandingActivities = brandingActivities;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetBrandingActivityByIdResult {
  brandingActivity?: BrandingActivity;
  error?: string;
}

interface GetBrandingActivityByIdResponse {
  brandingActivity: BrandingActivity;
}

const getBrandingActivityById = async (brandingActivityId: string) => {
  const result: GetBrandingActivityByIdResult = {};
  try {
    const {
      data: { brandingActivity },
    } = await API.get<GetBrandingActivityByIdResponse>(
      `branding-activities/${brandingActivityId}`,
      {
        transformResponse: (data) => {
          data = JSON.parse(data);
          data.brandingActivity = transformBrandingActivity(data.brandingActivity);
          return data;
        },
      },
    );
    result.brandingActivity = brandingActivity;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const prepareBrandingActivityData = (brandingActivity: BrandingActivity): FormData => {
  const formatData = new FormData();

  delete brandingActivity.brandingType;
  delete brandingActivity.visibilityElement;
  delete brandingActivity.promoter;
  delete brandingActivity.createdTime;

  brandingActivity.clientId = brandingActivity.client?.id ?? '';

  delete brandingActivity.client;

  for (let key in brandingActivity) {
    // @ts-ignore
    formatData.append(key, brandingActivity[key]);
  }

  formatData.delete('attachments');

  brandingActivity.attachments?.forEach((attachment) => {
    if (attachment.file) {
      formatData.append('attachmentFiles', attachment.file);
    }
  });

  formatData.delete('acknowledgements');
  
  brandingActivity.acknowledgements?.forEach((acknowledgement) => {
    if (acknowledgement.file) {
      formatData.append('acknowledgementFiles', acknowledgement.file);
    }
  });
  return formatData;
};

interface CreateBrandingActivityResponse {
  brandingActivity: BrandingActivity;
  message: string;
}
interface CreateBrandingActivityResult {
  brandingActivity?: BrandingActivity;
  message?: string;
  error?: string;
}

interface CreateBrandingActivityOption {
  onUploadProgress?: (event: any) => void;
}
const createBrandingActivity = async (
  brandingActivityData: BrandingActivity,
  { onUploadProgress }: CreateBrandingActivityOption,
) => {
  const result: CreateBrandingActivityResult = {};
  try {
    const formatData = prepareBrandingActivityData(brandingActivityData);

    const {
      data: { brandingActivity, message },
    } = await API.post<CreateBrandingActivityResponse>('branding-activities', formatData, {
      onUploadProgress: (event) => {
        onUploadProgress?.((event.loaded / event.total) * 100);
      },
    });
    result.brandingActivity = brandingActivity;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateBrandingActivityResponse {
  brandingActivity: BrandingActivity;
  message: string;
}

interface UpdateBrandingActivityResult {
  brandingActivity?: BrandingActivity;
  message?: string;
  error?: string;
}

interface UpdateBrandingActivityOption {
  onUploadProgress?: (event: any) => void;
}

const updateBrandingActivity = async (
  brandingActivityData: BrandingActivity,
  { onUploadProgress }: UpdateBrandingActivityOption,
) => {
  const result: UpdateBrandingActivityResult = {};
  try {
    const formatData = prepareBrandingActivityData(brandingActivityData);

    const {
      data: { brandingActivity, message },
    } = await API.put<UpdateBrandingActivityResponse>(
      `branding-activities/${brandingActivityData.id}`,
      formatData,
      {
        onUploadProgress: (event) => {
          onUploadProgress?.((event.loaded / event.total) * 100);
        },
      },
    );
    result.brandingActivity = brandingActivity;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteBrandingActivityResult {
  message?: string;
  error?: string;
}
interface DeleteBrandingActivityResponse {
  message: string;
}

const deleteBrandingActivity = async (id: string): Promise<DeleteBrandingActivityResult> => {
  const result: DeleteBrandingActivityResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteBrandingActivityResponse>(`branding-activities/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateBrandingActivityResult {
  message?: string;
  error?: string;
}
interface DeactivateBrandingActivityResponse {
  message: string;
}

const deactivateBrandingActivity = async (
  id: string,
): Promise<DeactivateBrandingActivityResult> => {
  const result: DeleteBrandingActivityResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateBrandingActivityResponse>(`branding-activities/${id}/deactivate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateBrandingActivityResult {
  message?: string;
  error?: string;
}
interface ActivateBrandingActivityResponse {
  message: string;
}
const activateBrandingActivity = async (id: string): Promise<ActivateBrandingActivityResult> => {
  const result: DeleteBrandingActivityResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateBrandingActivityResponse>(`branding-activities/${id}/activate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteAttachmentResult {
  message?: string;
  error?: string;
}
interface DeleteAttachmentResponse {
  message: string;
}

const deleteAttachment = async (
  id: string,
  attachmentId: string,
): Promise<DeleteAttachmentResult> => {
  const result: DeleteAttachmentResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteAttachmentResponse>(
      `branding-activities/${id}/attachments/${attachmentId}`,
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteAcknowledgementResult {
  message?: string;
  error?: string;
}
interface DeleteAcknowledgementResponse {
  message: string;
}

const deleteAcknowledgement = async (
  id: string,
  acknowledgementId: string,
): Promise<DeleteAcknowledgementResult> => {
  const result: DeleteAcknowledgementResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteAcknowledgementResponse>(
      `branding-activities/${id}/acknowledgements/${acknowledgementId}`,
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ApproveBrandingActivityResult {
  message?: string;
  error?: string;
}
interface ApproveBrandingActivityResponse {
  message: string;
}

const approveBrandingActivity = async (id: string): Promise<ApproveBrandingActivityResult> => {
  const result: ApproveBrandingActivityResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ApproveBrandingActivityResponse>(`branding-activities/${id}/approve`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DownloadActivitySheetResult {
  data?: ArrayBuffer;
  error?: string;
}

const downloadActivitySheet = async (
  options: GeAllBrandingActivityOptions,
): Promise<DownloadActivitySheetResult> => {
  const result: DownloadActivitySheetResult = {};
  try {
    const { data } = await API.post<ArrayBuffer>(
      'branding-activities/download/activity-sheet',
      parseGetAllBrandingActivityOptions(options),
      {
        responseType: 'arraybuffer',
      },
    );
    result.data = data;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const BrandingActivityService = {
  getAllBrandingActivity,
  getBrandingActivityById,
  createBrandingActivity,
  updateBrandingActivity,
  deleteBrandingActivity,
  deactivateBrandingActivity,
  activateBrandingActivity,
  deleteAttachment,
  deleteAcknowledgement,
  approveBrandingActivity,
  downloadActivitySheet,
};

export default BrandingActivityService;
