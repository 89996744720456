import { format } from 'date-fns';
import { PATTERN_DATE, PATTERN_DATE_TIME } from '../config/date.constant';
import isBefore from 'date-fns/isBefore';

export function formatDateTime(date: Date): string {
  return format(date, PATTERN_DATE_TIME);
}

export function formatDate(date: Date): string {
  return format(date, PATTERN_DATE);
}

export function getDateOnly(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function isDateBefore(dateToCompare: Date, date: Date) {
  return isBefore(getDateOnly(dateToCompare), getDateOnly(date));
}
