import React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../store';
import { AuthState } from '../store/auth/types';

export default function withRoles<P>(Component: React.ComponentType<P>, roles: string[]) {
  const WithRolesWrapper: FunctionComponent<P> = ({ ...props }: P) => {
    const history = useHistory();
    const { user, sessionLoading, authenticated } = useSelector<RootState, AuthState>(
      (state) => state.authState,
    );
    const hasRoles = user?.roles?.some((role) => roles.indexOf(role.key) > -1);

    if (sessionLoading) {
      return <>Loading</>;
    }

    if (!authenticated || !hasRoles) {
      history.replace('/404');
    }
    return <Component {...props} />;
  };
  return WithRolesWrapper;
}
