import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppDowload from '../pages/AppDowload';
import BrandingActivities from '../pages/BrandingActivities';
import CreateBrandingActivity from '../pages/BrandingActivities/CreateBrandingActivity';
import EditBrandingActivity from '../pages/BrandingActivities/EditBrandingActivity';
import ViewBrandingActivity from '../pages/BrandingActivities/ViewBrandingActivity';
import BrandingTypes from '../pages/BrandingTypes';
import Clients from '../pages/Clients';
import ClientUsers from '../pages/Clients/ClientUsers';
import Dashboard from '../pages/Dashboard';
import LoginPage from '../pages/Login';
import Modules from '../pages/Modules';
import PageNotFound from '../pages/PageNotFound';
import Roles from '../pages/Roles';
import UserList from '../pages/Users';
import CreateUser from '../pages/users/CreateUser';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import VisibilityElements from '../pages/VisibilityElements';
import { loadSession } from '../store/auth/actions';

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSession());
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/management/users" component={UserList} />
        <Route exact path="/management/users/new" component={CreateUser} />
        <Route exact path="/management/users/:id" component={ViewUser} />
        <Route exact path="/management/users/:id/edit" component={EditUser} />
        <Route exact path="/management/roles" component={Roles} />
        <Route exact path="/management/modules" component={Modules} />
        <Route exact path="/management/brands" component={Clients} />
        <Route exact path="/management/brands/:id/clients" component={ClientUsers} />
        <Route exact path="/branding/activities" component={BrandingActivities} />
        <Route exact path="/branding/activities/new" component={CreateBrandingActivity} />
        <Route exact path="/branding/activities/:id" component={ViewBrandingActivity} />
        <Route exact path="/branding/activities/:id/edit" component={EditBrandingActivity} />
        <Route exact path="/branding/branding-types" component={BrandingTypes} />
        <Route exact path="/branding/visbility-elements" component={VisibilityElements} />
        <Route exact path="/download/app" component={AppDowload} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/404" component={PageNotFound} />

        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
