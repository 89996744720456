import { createStore, applyMiddleware, combineReducers, Middleware } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './auth/reducers';

const rootReducer = combineReducers({
  authState: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const bindMiddleware = (middlewares: Middleware[]) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middlewares));
  }
  return applyMiddleware(...middlewares);
};

export const initStore = () => createStore(rootReducer, bindMiddleware([thunk]));
