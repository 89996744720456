import React from 'react';
import {
  Button,
  Card,
  CardContent,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { setUser } from '../store/auth/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { LoginFormData } from '../@types/login';
import LocationSvg from '../assets/images/location.svg';
import withoutAuth from '../hocs/withoutAuth';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import loginFormSchema from '../validation/login';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .login-image {
    width: 80%;
    max-width: 400px;
    margin: auto;
  }
  .login-form-col {
    background-color: var(--color);
  }
  .login-form-container {
    width: 80%;
    max-width: 500px;
    margin: auto;
  }
`;

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [loginFormData, setLoginFormData] = useState<LoginFormData>({ mobile: '', password: '' });
  const [openSnack, setOpenSnackbar] = useState(false);
  const [error, setError] = useState('false');
  const { register, errors, handleSubmit } = useForm<LoginFormData>({
    defaultValues: { mobile: '', password: '' },
    mode: 'onSubmit',
    resolver: joiResolver(loginFormSchema),
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const doLogin = async (loginFormData: LoginFormData) => {
    setOpenSnackbar(false);
    setError('');
    const { user, error } = await AuthService.login(loginFormData);

    if (error) {
      setOpenSnackbar(true);
      setError(error);
    } else if (user) {
      await dispatch(setUser(user));
      history.replace('/dashboard');
    }
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const onSubmit = handleSubmit((data) => {
    doLogin(data);
  });

  return (
    <LoginWrapper>
      <div className="hidden md:flex md:w-5/12 h-full items-center">
        <img src={LocationSvg} alt="login-cover" className="login-image block" />
      </div>
      <div className="w-full md:w-7/12 h-full login-form-col flex items-center">
        <div className="login-form-container">
          <div className="mb-8 text-center">
            <Typography variant="h4" className="text-white font-bold">
              India One Brand Service
            </Typography>
          </div>
          <Card className="login-card">
            <CardContent className="p-8">
              <Typography variant="h5" className="text-black text-center">
                Login
              </Typography>
              <form className="flex flex-col my-5" onSubmit={onSubmit}>
                <TextField
                  variant="outlined"
                  label="Mobile"
                  className="mb-5"
                  id="mobile"
                  name="mobile"
                  error={!!errors.mobile}
                  helperText={errors.mobile?.message}
                  inputRef={register}
                />
                <TextField
                  variant="outlined"
                  label="password"
                  className="mb-8"
                  id="pass"
                  name="password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  inputRef={register}
                  type={passwordVisible ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
                          {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  className="mb-5"
                  type="submit"
                  // disabled={!isValid}
                >
                  Login
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
      <Snackbar
        open={openSnack}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={5000}
        onClose={handleClose}
        message={error}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </LoginWrapper>
  );
};

export default withoutAuth(LoginPage, '/dashboard');
