import { Box, Typography, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { User } from '../../@types/user';
import Breadcrumbs, { BreadcrumbsLink } from '../../components/common/Breadcrumbs/Breadcrumbs';
import Empty from '../../components/common/Empty';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import MainLayout from '../../components/MainLayout';
import UserForm from '../../containers/UserForm';
import withAuth from '../../hocs/withAuth';
import UserService from '../../services/user.service';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Management',
    href: '/management/users',
  },
  {
    label: 'User',
    href: '/management/users',
  },
];
const EditUser = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState(false);

  const getUserById = async (id: string) => {
    setLoading(true);
    const { user, error } = await UserService.getUserById(id);
    setLoading(false);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (user) {
      setUser(user);
    }
  };

  useEffect(() => {
    getUserById(id);
    // eslint-disable-next-line
  }, []);

  const handleSave = async (data: User) => {
    const { user: updatedUser, error } = await UserService.updateUser({
      ...user,
      ...data,
      identity: { id: user?.identity?.id, ...data.identity },
      address: data.address && { id: user?.address?.id, ...data.address },
    });
    if (error) {
    } else if (updatedUser) {
      history.push(`/management/users/${updatedUser.id}`);
    }
  };

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Breadcrumbs links={links} />
          <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
            Edit User
          </Typography>
          <Box marginTop={2}>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={!user} className="mt-8" imgClassName="w-4/12">
                {user && <UserForm mode="edit" user={user} onSave={handleSave} />}
              </Empty>
            </LoadingIndicator>
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(EditUser, '/login');
