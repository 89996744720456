import React from 'react';
import styled from 'styled-components';
import { BrandingActivityStatus } from '../../enums/branding';

const ActivityStatusTagWrapper = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: #fff;
  text-transform: capitalize;

  &.pending {
    background-color: #FF754C;
  }
  &.approved {
    background-color: #7fba7a;
  }
`;
interface ActivityStatusTagProps {
  status: BrandingActivityStatus;
}

const ActivityStatusTag: React.FunctionComponent<ActivityStatusTagProps> = ({ status }) => {
  return <ActivityStatusTagWrapper className={status}>{status}</ActivityStatusTagWrapper>;
};

export default ActivityStatusTag;
