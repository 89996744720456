import { Client,FilterType } from '../@types';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';
import qs from 'qs';

interface GeAllClientOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: FilterType;
}

interface GetAllClientResult {
  clients?: Client[];
  count?: number;
  error?: string;
}

interface GetAllClientResponse {
  clients: Client[];
  count: number;
}

const getAllClient = async ({ page, size, query, filterType }: GeAllClientOptions) => {
  const result: GetAllClientResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, filterType });
    const {
      data: { clients, count },
    } = await API.get<GetAllClientResponse>(`/clients?${queryString}`, {});
    result.clients = clients;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetClientByIdResult {
  client?: Client;
  error?: string;
}

interface GetClientByIdResponse {
  client: Client;
}

const getClientById = async (clientId: string) => {
  const result: GetClientByIdResult = {};
  try {
    const {
      data: { client },
    } = await API.get<GetClientByIdResponse>(`/clients/${clientId}`);
    result.client = client;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateClientResponse {
  client: Client;
  message: string;
}
interface CreateClientResult {
  client?: Client;
  message?: string;
  error?: string;
}

const createClient = async (clientData: Client) => {
  const result: CreateClientResult = {};
  try {
    const {
      data: { client, message },
    } = await API.post<CreateClientResponse>('/clients', clientData);
    result.client = client;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateClientResponse {
  client: Client;
  message: string;
}

interface UpdateClientResult {
  client?: Client;
  message?: string;
  error?: string;
}

const updateClient = async (clientData: Client) => {
  const result: UpdateClientResult = {};
  try {
    // const clientFormData: ClientFormData = transformToClientFormData(clientData);
    const {
      data: { client, message },
    } = await API.put<UpdateClientResponse>(
      `clients/${clientData.id}`,
      clientData,
    );
    result.client = client;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteClientResult {
  message?: string;
  error?: string;
}
interface DeleteClientResponse {
  message: string;
}

const deleteClient = async (id: string): Promise<DeleteClientResult> => {
  const result: DeleteClientResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteClientResponse>(`/clients/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateClientResult {
  message?: string;
  error?: string;
}
interface DeactivateClientResponse {
  message: string;
}

const deactivateClient = async (id: string): Promise<DeactivateClientResult> => {
  const result: DeleteClientResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateClientResponse>(`/clients/${id}/deactivate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateClientResult {
  message?: string;
  error?: string;
}
interface ActivateClientResponse {
  message: string;
}
const activateClient = async (id: string): Promise<ActivateClientResult> => {
  const result: DeleteClientResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateClientResponse>(`/clients/${id}/activate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const ClientService = {
  getAllClient,
  getClientById,
  createClient,
  updateClient,
  deleteClient,
  deactivateClient,
  activateClient,
};

export default ClientService;
