import { BrandingActivityFilterOption } from '../@types';
import useHistoryState from './useHistoryState';

export default function useBrandingActivityFilterOption(
  defaultFilterOption: BrandingActivityFilterOption,
) {
  const state = useHistoryState<BrandingActivityFilterOption>();

  return state ?? defaultFilterOption;
}
