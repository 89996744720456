export enum BrandingActivityStatus {
  ALL = 'all',
  PENDING = 'pending',
  APPROVED = 'approved',
}

export enum ActivityType {
  ALL = 'all',
  RECEE = 'recee',
  INSTALLATION = 'installation',
  RECTIFICATION = 'rectification',
}
