import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
} from '@material-ui/core';
import React from 'react';
import { BrandingActivity } from '../../../@types/branding';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { createGoogleMapLink } from '../../../helpers/map';

interface LocationProps {
  brandingActivity: BrandingActivity;
}
const Location: React.FunctionComponent<LocationProps> = ({ brandingActivity }) => {
  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">Location</Typography>}
        action={
          brandingActivity.lat &&
          brandingActivity.lng && (
            <Link
              href={createGoogleMapLink(brandingActivity.lat, brandingActivity.lng)}
              rel="noopener noreferrer"
              target="_blank"
            >
              Open in Google Map
            </Link>
          )
        }
        classes={{
          action: 'm-0 self-center',
        }}
      />
      <Divider />
      {brandingActivity.lat !== undefined && brandingActivity.lng !== undefined && (
        <Map
          center={{ lat: brandingActivity.lat, lng: brandingActivity.lng }}
          zoom={11}
          style={{ height: '400px' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={{ lat: brandingActivity.lat, lng: brandingActivity.lng }}></Marker>
        </Map>
      )}

      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">Town </TableCell>
            <TableCell>
              <Typography className="capitalize">{brandingActivity.town}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">District </TableCell>
            <TableCell>
              <Typography className="capitalize">{brandingActivity.district}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">State </TableCell>
            <TableCell>
              <Typography className="capitalize">{brandingActivity.state}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default Location;
