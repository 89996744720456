import {
  makeStyles,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import React, { useState } from 'react';
import { BrandingActivity } from '../../@types/branding';
import ActivityStatusTag from '../../components/ActivityStatusTag';
import DisplayDate from '../../components/DisplayDate';
import CanView from '../../components/RoleBasedAccess/CanView';
import { ROLE } from '../../enums/role';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { formatDateTime } from '../../util/datetime';
import cx from 'classnames';
import { ActivityType } from '../../enums/branding';

interface ActvityTableRowProps {
  brandingActivity: BrandingActivity;
  index: number;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const TypographyTableCell: React.FunctionComponent = ({ children }) => {
  return (
    <TableCell>
      <Typography className="capitalize" variant="body2">
        {children}
      </Typography>
    </TableCell>
  );
};
const ActivityTableRow: React.FC<ActvityTableRowProps> = ({ brandingActivity }) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const handlePropogation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <TableRow
        className={cx(classes.root, 'cursor-pointer')}
        hover
        component="a"
        href={`/branding/activities/${brandingActivity.id}`}
      >
        <TableCell scope="row" className="w-8 p-2">
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{brandingActivity.displayId}</TableCell>
        <TableCell>
          {brandingActivity.activityDate && (
            <Tooltip title={formatDateTime(brandingActivity.activityDate)} placement="top">
              <span className="whitespace-nowrap">
                <DisplayDate date={brandingActivity.activityDate} />
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Typography className="capitalize font-medium" variant="body2">
            {brandingActivity.outletName}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {brandingActivity.outletMobile}
          </Typography>
          {brandingActivity.outletCode && (
            <Typography color="textSecondary" variant="body2">
              Code - {brandingActivity.outletCode}
            </Typography>
          )}
        </TableCell>
        <TableCell align="left">
          <Typography className="capitalize" variant="body2">
            {brandingActivity.town} , {brandingActivity.state}
          </Typography>
        </TableCell>
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
          <TypographyTableCell>{brandingActivity.client?.name}</TypographyTableCell>
        </CanView>
        <TypographyTableCell>{brandingActivity.activityType}</TypographyTableCell>
        <TableCell>
          <Typography className="capitalize" variant="body2">
            {brandingActivity.brandingType?.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className="capitalize" variant="body2">
            {brandingActivity.visibilityElement?.name}
          </Typography>
        </TableCell>
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
          <TableCell>
            <Tooltip title={brandingActivity.promoter?.name ?? ''}>
              <Typography
                className="capitalize whitespace-nowrap overflow-ellipsis overflow-hidden"
                variant="body2"
                style={{ width: '100px' }}
              >
                <Link
                  to={`/management/users/${brandingActivity.promoter?.id}`}
                  className="hover:underline"
                  onClick={handlePropogation}
                >
                  {brandingActivity.promoter?.name}
                </Link>
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell>
            <ActivityStatusTag status={brandingActivity.status} />
          </TableCell>
        </CanView>

        <TableCell align="center" className="whitespace-nowrap">
          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
            <IconButton
              component={Link}
              to={`/branding/activities/${brandingActivity.id}/edit`}
              onClick={handlePropogation}
              size="small"
            >
              <EditIcon className="w-6 h-6" />
            </IconButton>
          </CanView>
          <IconButton
            component={Link}
            to={`/branding/activities/${brandingActivity.id}`}
            onClick={handlePropogation}
            size="small"
            className="mr-2"
          >
            <ArrowForwardIcon className="w-6 h-6" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} className={cx({ 'p-0': !open })}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Other detail
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {brandingActivity.activityType === ActivityType.RECEE && (
                      <React.Fragment>
                        <TableCell className="font-medium" align="left">
                          Width in Inches
                        </TableCell>
                        <TableCell className="font-medium" align="left">
                          Height in Inches
                        </TableCell>
                        <TableCell className="font-medium" align="left">
                          Quantity
                        </TableCell>
                      </React.Fragment>
                    )}
                    <TableCell className="font-medium" align="left">
                      District
                    </TableCell>
                    <TableCell className="font-medium" align="left">
                      Distributer
                    </TableCell>
                    <TableCell className="font-medium" align="left">
                      {' '}
                      Sales Person
                    </TableCell>

                    <TableCell className="font-medium" colSpan={3}>
                      Remark
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {brandingActivity.activityType === ActivityType.RECEE && (
                      <React.Fragment>
                        <TableCell align="left">{brandingActivity.boardWidth} inches</TableCell>
                        <TableCell align="left">{brandingActivity.boardHeight} inches</TableCell>
                        <TableCell align="left">{brandingActivity.quantity}</TableCell>
                      </React.Fragment>
                    )}
                    <TableCell align="left">{brandingActivity.district}</TableCell>

                    <TableCell className="capitalize font-medium">
                      {brandingActivity.distributerName}
                    </TableCell>
                    <TableCell>
                      <Typography className="capitalize font-medium">
                        {brandingActivity.salesPersonName}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {brandingActivity.salesPersonMobile}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3}>{brandingActivity.remark}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ActivityTableRow;
