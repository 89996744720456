import { Box, Button, Divider, Grid, Tab, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsLink } from '../../components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from '../../components/MainLayout';
import withAuth from '../../hocs/withAuth';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { BrandingActivity } from '../../@types/branding';
import BrandingActivityService from '../../services/branding-activity.service';
import Empty from '../../components/common/Empty';
import BrandingActivityDetail from '../../containers/BrandingActivity/BrandingActivityDetail';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PromoterDetail from '../../containers/BrandingActivity/PromoterDetail';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useSnackbar } from 'notistack';
import DeleteBrandingActivityDialog from '../../containers/BrandingActivity/DeleteBrandingActivityDialog';
import CanView from '../../components/RoleBasedAccess/CanView';
import { ROLE } from '../../enums/role';
import withRoles from '../../hocs/withRoles';
import { hasAnyRoleTillManager } from '../../util/role';
import { RootState } from '../../store';
import { AuthState } from '../../store/auth/types';
import { useSelector } from 'react-redux';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Branding',
    href: '/branding/activities',
  },
  {
    label: 'Branding Activities',
    href: '/branding/activities',
  },
];
const ViewBrandingActivity = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector<RootState, AuthState>((state) => state.authState);

  const { id } = useParams<{ id: string }>();

  const [brandingActivity, setBrandingActivity] = useState<BrandingActivity | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setTab] = useState('detail');
  const [openDeleteBrandingActivityDialog, setOpenDeleteBrandingActivityDialog] = useState(false);

  const goToPrevPage = () => {
    if (history.length > 1) {
      history.go(-1);
    } else {
      history.push('/branding/activities');
    }
  };
  
  const getBrandingActivityById = async (id: string) => {
    setLoading(true);
    const { brandingActivity, error } = await BrandingActivityService.getBrandingActivityById(id);
    setLoading(false);
    if (error) {
    } else if (brandingActivity) {
      setBrandingActivity(brandingActivity);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: string) => {
    setTab(newValue);
  };

  const deleteBrandingActivity = async () => {
    if (brandingActivity) {
      const { message, error } = await BrandingActivityService.deleteBrandingActivity(
        brandingActivity.id,
      );
      if (message) {
        enqueueSnackbar(message, { variant: 'success' });
        goToPrevPage();
      } else if (error) {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };
  const showDeleteBrandingActivityDialog = () => {
    setOpenDeleteBrandingActivityDialog(true);
  };
  const closeDeleteBrandingActivityDialog = () => {
    setOpenDeleteBrandingActivityDialog(false);
  };

  const approveActivity = async () => {
    if (brandingActivity) {
      const { message, error } = await BrandingActivityService.approveBrandingActivity(
        brandingActivity.id,
      );
      if (message) {
        enqueueSnackbar(message, { variant: 'success' });
        goToPrevPage();
      } else if (error) {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };

  const getTabs = () => {
    const tabs = [{ label: 'Detail', value: 'detail' }];
    if (user && hasAnyRoleTillManager(user.roles)) {
      tabs.push({ label: 'Promoter', value: 'promoter' });
    }
    return tabs;
  };

  useEffect(() => {
    getBrandingActivityById(id);
    // eslint-disable-next-line
  }, []);

  const tabs = getTabs();

  return (
    <MainLayout>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Breadcrumbs links={links} />
          {brandingActivity && (
            <div className="flex items-center mt-3">
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2 uppercase">
                Branding Activity Detail
              </Typography>
            </div>
          )}
        </Grid>
        {brandingActivity && (
          <Grid item xs={12} sm={12} md={6}>
            <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
              <Box className="w-full flex lg:justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className="mr-2"
                  onClick={approveActivity}
                >
                  <ThumbUpIcon className="mr-2" />
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component={Link}
                  to={`/branding/activities/${brandingActivity?.id}/edit`}
                  className="mr-2"
                >
                  <EditIcon className="mr-2" />
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={showDeleteBrandingActivityDialog}
                  className="mr-2"
                >
                  <DeleteIcon className="mr-2" />
                  Delete
                </Button>
              </Box>
            </CanView>
          </Grid>
        )}
      </Grid>
      <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
        <Empty isEmpty={!brandingActivity} imgClassName="w-4/12">
          <TabContext value={currentTab}>
            <TabList className="mt-3" indicatorColor="primary" onChange={handleTabChange}>
              {tabs.map((tab) => (
                <Tab label={tab.label} value={tab.value} key={tab.value} />
              ))}
            </TabList>

            <Divider />
            <Box>
              <TabPanel value="detail">
                {brandingActivity && <BrandingActivityDetail brandingActivity={brandingActivity} />}
              </TabPanel>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
                <TabPanel value="promoter">
                  {brandingActivity?.promoter && (
                    <PromoterDetail promoter={brandingActivity?.promoter} />
                  )}
                </TabPanel>
              </CanView>
            </Box>
          </TabContext>
        </Empty>
      </LoadingIndicator>
      {openDeleteBrandingActivityDialog && (
        <DeleteBrandingActivityDialog
          open={openDeleteBrandingActivityDialog}
          onClose={closeDeleteBrandingActivityDialog}
          onDelete={deleteBrandingActivity}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(ViewBrandingActivity, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]),
  '/login',
);
