import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import React from 'react';
import DialogTitle from '../../components/common/Dialog/DialogTitle';

interface DeleteBrandingActivityDialogProps {
  open: boolean;
  onClose?: () => void;
  onDelete: () => void;
}

const DeleteBrandingActivityDialog: React.FC<DeleteBrandingActivityDialogProps> = ({
  open,
  onClose,
  onDelete,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <DialogTitle title="Delete branding activity" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you sure you want to delete the branding activity? The changes can't be reverted
          back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBrandingActivityDialog;
