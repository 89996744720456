import { Grid } from '@material-ui/core';
import React from 'react';
import { BrandingActivity } from '../../../@types/branding';
import { ActivityType } from '../../../enums/branding';
import Acknowledgement from './Acknowledgement';
import ActivityInfo from './ActivityInfo';
import Attachment from './Attachment';
import Location from './Location';

interface BrandingActivityDetailProps {
  brandingActivity: BrandingActivity;
}

const BrandingActivityDetail: React.FC<BrandingActivityDetailProps> = ({ brandingActivity }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ActivityInfo brandingActivity={brandingActivity} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Location brandingActivity={brandingActivity} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Attachment brandingActivity={brandingActivity} />
      </Grid>
      {brandingActivity.activityType !== ActivityType.RECTIFICATION && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Acknowledgement brandingActivity={brandingActivity} />
        </Grid>
      )}
    </Grid>
  );
};

export default BrandingActivityDetail;
