import { Box, Typography, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../@types/user';
import Breadcrumbs, { BreadcrumbsLink } from '../../components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from '../../components/MainLayout';
import UserForm from '../../containers/UserForm';
import { Gender } from '../../enums/gender';
import withAuth from '../../hocs/withAuth';
import UserService from '../../services/user.service';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Management',
    href: '/management/users',
  },
  {
    label: 'User',
    href: '/management/users',
  },
];

const CreateUser = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [user] = useState<User>({
    id: '',
    name: '',
    mobile: '',
    dob: new Date(),
    gender: Gender.MALE,
    modules: [],
    roles: [],
    address: { state: '', city: '', pincode: '', addressLine: '' },
    identity: { adhaarcard: '', pancard: '' },
  });

  const handleSave = async (data: User) => {
    const { user, error } = await UserService.createUser(data);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (user) {
      history.push(`/management/users/${user.id}`);
    }
  };

  return (
    <MainLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Breadcrumbs links={links} />
          <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
            Create User
          </Typography>
          <Box marginTop={2}>
            <UserForm mode="create" user={user} onSave={handleSave} />
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(CreateUser, '/login');
