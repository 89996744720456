import { Dispatch } from 'redux';
import { User } from '../../@types/user';
import LoggedUserService from '../../services/logged-user.service';
import {
  LOGOUT,
  LogoutAction,
  SessionLoadErrorAction,
  SessionLoadSuccessAction,
  SESSION_LOAD_ERROR,
  SESSION_LOAD_SUCCESS,
  SetUserAction,
  SET_USER,
  StartSessionLoadingAction,
  START_SESSION_LOADING,
} from './types';

export const setUser = (user: User): SetUserAction => {
  return {
    type: SET_USER,
    payload: {
      user,
    },
  };
};

export const logout = (): LogoutAction => {
  return { type: LOGOUT };
};

const startSessionLoading = (): StartSessionLoadingAction => {
  return {
    type: START_SESSION_LOADING,
  };
};

const sessionLoadSuccess = (user: User): SessionLoadSuccessAction => {
  return {
    type: SESSION_LOAD_SUCCESS,
    payload: {
      user,
    },
  };
};

const sessionLoadError = (): SessionLoadErrorAction => {
  return {
    type: SESSION_LOAD_ERROR,
  };
};

export const loadSession = () => async (
  dispatch: Dispatch<StartSessionLoadingAction | SessionLoadSuccessAction | SessionLoadErrorAction>,
) => {
  dispatch(startSessionLoading());
  const { user, error } = await LoggedUserService.getProfileDetail();
  if (user) {
    dispatch(sessionLoadSuccess(user));
  } else if (error) {
    dispatch(sessionLoadError());
  }
};
