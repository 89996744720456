import { VisibilityElement } from '../@types/branding';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';
import qs from 'qs';
import { FilterType } from '../@types';

interface GeAllVisibilityElementOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: FilterType;
}

interface GetAllVisibilityElementResult {
  visibilityElements?: VisibilityElement[];
  count?: number;
  error?: string;
}

interface GetAllVisibilityElementResponse {
  visibilityElements: VisibilityElement[];
  count: number;
}

const getAllVisibilityElement = async ({
  page,
  size,
  query,
  filterType,
}: GeAllVisibilityElementOptions) => {
  const result: GetAllVisibilityElementResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, filterType });
    const {
      data: { visibilityElements, count },
    } = await API.get<GetAllVisibilityElementResponse>(`/visibility-elements?${queryString}`, {});
    result.visibilityElements = visibilityElements;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetVisibilityElementByIdResult {
  visibilityElement?: VisibilityElement;
  error?: string;
}

interface GetVisibilityElementByIdResponse {
  visibilityElement: VisibilityElement;
}

const getVisibilityElementById = async (visibilityElementId: string) => {
  const result: GetVisibilityElementByIdResult = {};
  try {
    const {
      data: { visibilityElement },
    } = await API.get<GetVisibilityElementByIdResponse>(
      `visibility-elements/${visibilityElementId}`,
    );
    result.visibilityElement = visibilityElement;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateVisibilityElementResponse {
  visibilityElement: VisibilityElement;
  message: string;
}
interface CreateVisibilityElementResult {
  visibilityElement?: VisibilityElement;
  message?: string;
  error?: string;
}

const createVisibilityElement = async (visibilityElementData: VisibilityElement) => {
  const result: CreateVisibilityElementResult = {};
  try {
    const {
      data: { visibilityElement, message },
    } = await API.post<CreateVisibilityElementResponse>(
      'visibility-elements',
      visibilityElementData,
    );
    result.visibilityElement = visibilityElement;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateVisibilityElementResponse {
  visibilityElement: VisibilityElement;
  message: string;
}

interface UpdateVisibilityElementResult {
  visibilityElement?: VisibilityElement;
  message?: string;
  error?: string;
}

const updateVisibilityElement = async (visibilityElementData: VisibilityElement) => {
  const result: UpdateVisibilityElementResult = {};
  try {
    // const visibilityElementFormData: VisibilityElementFormData = transformToVisibilityElementFormData(visibilityElementData);
    const {
      data: { visibilityElement, message },
    } = await API.put<UpdateVisibilityElementResponse>(
      `visibility-elements/${visibilityElementData.id}`,
      visibilityElementData,
    );
    result.visibilityElement = visibilityElement;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteVisibilityElementResult {
  message?: string;
  error?: string;
}
interface DeleteVisibilityElementResponse {
  message: string;
}

const deleteVisibilityElement = async (id: string): Promise<DeleteVisibilityElementResult> => {
  const result: DeleteVisibilityElementResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteVisibilityElementResponse>(`visibility-elements/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateVisibilityElementResult {
  message?: string;
  error?: string;
}
interface DeactivateVisibilityElementResponse {
  message: string;
}

const deactivateVisibilityElement = async (
  id: string,
): Promise<DeactivateVisibilityElementResult> => {
  const result: DeleteVisibilityElementResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateVisibilityElementResponse>(`visibility-elements/${id}/deactivate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateVisibilityElementResult {
  message?: string;
  error?: string;
}
interface ActivateVisibilityElementResponse {
  message: string;
}
const activateVisibilityElement = async (id: string): Promise<ActivateVisibilityElementResult> => {
  const result: DeleteVisibilityElementResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateVisibilityElementResponse>(`visibility-elements/${id}/activate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const VisibilityElementService = {
  getAllVisibilityElement,
  getVisibilityElementById,
  createVisibilityElement,
  updateVisibilityElement,
  deleteVisibilityElement,
  deactivateVisibilityElement,
  activateVisibilityElement,
};

export default VisibilityElementService;
