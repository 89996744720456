import { LoginFormData } from '../@types/login';
import { User } from '../@types/user';
import API from '../helpers/api';
import { getErrorMessage } from '../helpers/axios';

interface LoginResponse {
  message: string;
  user: User;
  token: string;
}

interface LoginResult {
  user?: User;
  error?: any;
}

const login = async (credentials: LoginFormData): Promise<LoginResult> => {
  const result: LoginResult = {};
  try {
    const response = await API.post<LoginResponse>('/auth/login', credentials);
    result.user = response.data.user;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface LogoutResponse {
  message: string;
}

const logout = async (): Promise<LogoutResponse> => {
  const response = await API.post<LogoutResponse>('/auth/logout');
  return response.data;
};

const AuthService = {
  login,
  logout,
};

export default AuthService;
